import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/home/Home";
import WhatsAppForBusiness from "./components/pages/WhatsappBusinessApi";
import Thankyou from "./components/pages/Thankyou";
import TermsConditions from "./components/pages/TermsConditions";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import AboutUs from "./components/pages/AboutUs";
import ContactUs from "./components/pages/ContactUs";
import { HelmetProvider } from "react-helmet-async";
import Restaurantpossoftware from "./components/pages/Restaurantpossoftware";
import Faq from "./components/pages/Faq";
import RestaurantPointOfSaleSoftware from "./components/pages/RestaurantPointOfSaleSoftware";
import RestaurantInventoryManagement from "./components/pages/RestaurantInventoryManagement";
import RestaurantDirectOrderingSystem from "./components/pages/RestaurantDirectOrderingSystem";
import WhatsappBusinessApi from "./components/pages/WhatsappBusinessApi";

function App() {
  return (
    <HelmetProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/restaurant-point-of-sale-software"
          element={<RestaurantPointOfSaleSoftware />}
        />
        <Route
          path="/restaurant-direct-ordering-system"
          element={<RestaurantDirectOrderingSystem />}
        />
        <Route
          path="/restaurant-inventory-management"
          element={<RestaurantInventoryManagement />}
        />
        <Route
          path="/whatsapp-business-api"
          element={<WhatsappBusinessApi />}
        />
        <Route path="/termsconditions" element={<TermsConditions />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route
          path="/Restaurant-pos-software"
          element={<Restaurantpossoftware />}
        />
        <Route path="/faq" element={<Faq />} />
      </Routes>
    </HelmetProvider>
  );
}

export default App;
