// import "../home/rista.css"
// import styles from '../home/rista.module.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
//import axios from 'axios';
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useLocation } from "react-router";
import Tweeter from '../../imagesrista/s1.svg'
import Header from "../common/Header";
import { Helmet } from "react-helmet-async";
import Footer from "../common/Footer";
import styled from 'styled-components';
import backgroundImage from '../../imagesrista/leadgen-bg-web.webp';
import backgroundImage1 from '../../imagesrista/Hero-Fold.png';
import backgroundImage2 from '../../imagesrista/Last-Fold.png';
import backgroundImage3 from '../../imagesrista/mobile-last-Fold.png';
import backgroundImage4 from '../../imagesrista/mobile-Hero-Fold.png';
import ticks from '../../imagesrista/y-shape.png';
import { NavLink } from 'react-router-dom';

const addImportantToCSS = (css) => {
    return css
        .replace(/(\w+:\s*[^;]+)(;)/g, '$1 !important$2')
        .replace(/(\s*{[\s\S]*?})/g, '$1');
};


const Wrapper = styled.div`
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
section{
    width: 100%;
    float: left;
}
.header {
	background: #151515;
	padding: 19px 15px;
	border-bottom: 1px solid #2C2C2C;
	position: fixed;
	top: 0;
	z-index: 999;
}
.header .head-main {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.header .head-btn {
	padding: 8px 20px;
	border: 1px solid #FFFFFF;
	border-radius: 4px;
	text-decoration: none;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	text-align: center;
	color: #fff;
	display: block;
    font-family: "Inter", sans-serif;
}
.banner .bannering-img img {
	width: 100%;
}
.banner {
	background: #151515;
	padding: 30px 0 40px;
    margin-top: 81px;
}
.banner-heading {
    font-family: Plus Jakarta Sans;
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;
    text-align: left;
    color: #fff;
}
.banner-para {
    font-family: Plus Jakarta Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: #FFFFFF;
    opacity: 72%;
    margin: 24px 0 57px;
}
p.banner-para span {
    display: block;
}
.ban-bt {
	display: flex;
	justify-content: flex-start;
	margin: 13px 0 24px;
}
.ban-bt a {
	padding: 16px 42px;
	background: #1D4ED8;
	border-radius: 4px;
	font-family: Plus Jakarta Sans;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
	text-align: center;
	color: #fff;
	text-decoration: none;
}
.ban-bt a:hover {
    color: #fff;
}
.ban-last {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 500;
	text-align: left;
	color: #fff;
    margin:0;
}
.ban-last span {
	font-family: Inter;
	font-size: 13px;
	font-weight: 400;
	line-height: 32px;
	text-align: left;
	color: #fff;
	opacity: 80%;
}
.ban-first span {
    font-family: Plus Jakarta Sans;
    font-size: 13px;
    font-weight: 200;
    text-align: left;
    color: #fff;
    opacity: 72%;
    margin-bottom: 15px;
}
.ban-first {
	display: flex;
	justify-content: flex-start;
}
.sec-2 .restaurants-img img {
    height: 64px;
    width: 64px !important;
    /* filter: grayscale(100%);
    opacity: 0.7; */
}
.sec-2 .para {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 400;
    line-height: 100%;
    margin: 0 0 36px;
    text-align: center;
}
.sec-2 .para b {
    font-weight: 700;
}
.sec-2 {
	padding: 120px 0;
    margin:0;
}
.sec-3 .form-control::placeholder {
	color: #000000B2;
	opacity: 1;
}
.sec-3 .form-control {
	height: 52px;
	background: transparent;
	border-radius: 4px;
	margin: 12px 0;
	display: block;
	color: #000;
	padding: 10px 20px;
	border: 1px solid #00000052;
}
.sec-3 .form-control:focus {
	color: #000000;
	background-color: transparent;
	border-color: #000;
	outline: 0;
	box-shadow: none;
}
.sec-3 .form-control::placeholder:focus {
	color: #000;
	opacity: 1;
}
    .banner .row {
	align-items: center;
}
.sec-3 .contact-form .btn {
	background: #1D4ED8;
	border: 0;
	padding: 16px 42px;
	width: 100%;
	font-family: Inter;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
	text-align: center;
	margin: 12px 0 0 0;
}
.form-content h3 {
	font-family: Manrope;
	font-size: 16px;
	font-weight: 400;
	line-height: 125%;
	text-align: left;
	color: #000;
}
.form-content h2 {
	font-family: Manrope;
	font-size: 28px;
	font-weight: 700;
	line-height: 125%;
	letter-spacing: -1px;
	text-align: left;
	color: #000;
}
.form-content h4 {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 500;
	line-height: 36px;
	text-align: left;
	opacity: 50%;	
	color: #000;
}
.sec-3-inner {
	background: #FFF;
	margin: 0px 100px;
	border: 1px solid #DEDEDE;
	border-radius: 24px;
}
.text-black{
	color: #000;
}
.form-content-today {
	padding: 41px 57px 36px 16px;
}
.sub-heading {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin: 0 0 24px 0;
    line-height: 135%;
}
.heading {
	font-family: Manrope;
	font-size: 48px;
	font-weight: 800;
	line-height: 56px;
	letter-spacing: -1px;
	text-align: center;
}
.heading span {
	display: block;
}
.full-img {
	margin: 72px 0 56px;
}
.full-img img{
	width: 100%;
}0
.sec-4-inner h3 {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    line-height: 27px;
    text-align: left;
    color: #1D1D1D;
    margin-bottom: 24px;
}
.sec-4-inner p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #000;
    margin-bottom: 0;
}
.sec-4-inner-ch {
	margin-top: 56px;
    color: black;
}
.sec-4 .row-align {
	justify-content: center;
	margin: 70px 0 0 0;
}
.sec-4 .row-align .ban-bt a {
	width: 100%;
}
.sec-4 {
	padding: 0px 0 124px 0 !important;
    padding: 96px 0 33px;
    background: #ffffff;
    color: #fff;
    margin: 0 0 0 0;
    position: relative;
    z-index: 1;
}
.sec-7 h3 {
	font-family: Manrope;
	font-size: 18px;
	font-weight: 700;
	line-height: 26px;
	text-align: center;
	color: #fff;
	margin: 20px 0;
}
.sec-7 p {
	color: #fff;
	font-family: Inter;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	text-align: center;
	opacity: 69%;
	margin: 0;
}
.sec-7 .sec-7-block {
    display: flex !important;
    gap: 9px;
    padding: 64px 0 60px  !important;
    justify-content: center  !important;
}
.sec-7 .col-md-12 .ban-bt {
	justify-content: center;
	margin-bottom: 60px;
}
.sec-7 h3 span {
	display: block;
}
.sec5ins {
    color: #fff;
    text-align: center;
    border-top: 0.4px solid #FFFFFF59;
    padding: 18px 0 24px;
    line-height: normal;
}
.sec5ins b {
    font-weight: 600;
}

.hm_staricon {
	margin: 0 7px;
	position: relative;
	top: -1px;
}
small.et_customr {
	font-size: 11px;
	margin: 0 0 0 7px;
}
.hm_starline {
    font-size: 20px;
    line-height: 30px;
    padding-left: 8px;
    position: relative;
    top: 0px;
}
.secing-img-ban img {
	width: 100%;
	border-radius: 24px 0 0 24px;
	height: 100%;
	object-fit: cover;
}
.row-ceb-jsu{
	justify-content: center;
}
.secing-img-ban {
	height: 100%;
}
.sec-7 {
	background: #000;
	padding: 116px 0 0 0;
}
.banner-image {
	width: 100%;
	height: 100%;
	opacity: 24%;
	background: #cfbb9f;
	filter: blur(100px);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
    margin-top:0;
}


#main {
	margin: 50px 0;
  }
  
 #faq .card {
    margin-bottom: 20px;
    border: 0;
    border: 1px solid #E0E0E0;
}
  #faq2 .card {
	margin-bottom: 30px;
	border: 0;
	border: 1px solid #E0E0E0;
}
  
  #faq .card .card-header {
	border: 0;
	-webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
	box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
	border-radius: 2px;
	padding: 0;
  }
  #faq2 .card .card-header {
	border: 0;
	-webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
			box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
	border-radius: 2px;
	padding: 0;
  }
  
  #faq .card .card-header .btn-header-link {
    display: block;
    background: #FFF;
    color: #1D4ED8;
    padding: 30px 60px 20px;
    font-family: Manrope;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
}
#faq .card .card-header .btn-header-link.collapsed {
    padding: 20px 60px 20px;
}

#faq .card .card-header .btn-header-link::after {
    content: "+";
    font-size: 30px;
    font-weight: 500;
    float: left;
    position: absolute;
    top: 30px;
    left: 25px;
    color: #1D4ED8;
    transform: rotate(45deg);
}
#faq2 .card .card-header .btn-header-link::after {
	content: "+";
	font-size: 30px;
	font-weight: 500;
	float: left;
	position: absolute;
	top: 20px;
	left: 25px;
	color: #1D4ED8;
	transform: rotate(45deg);
}
 #faq .card .card-header .btn-header-link.collapsed {
    background: #f7f7f7;
    color: #000;
}
  #faq2 .card .card-header .btn-header-link.collapsed {
	background: #EEEEEE;
	color: #000;
}
  
#faq .card .card-header .btn-header-link.collapsed::after {
    content: "+";
    transform: rotate(0deg);
    color: #000;
    top: 20px;
}
#faq2 .card .card-header .btn-header-link.collapsed::after {
	content: "+";
	transform: rotate(0deg);
	color: #000;
}
.sec-5 {
	padding: 120px 0;
}
.sec-5 .subheading {
    margin: 0;
    font-weight: 400;
}
.sec-5 .heading {
	margin: 24px 0 32px;
}
.sec-5 .row.mid {
	margin: 64px 0 0 0;
	align-items: center;
}
#faq .card .card-body {
	min-height: 1px;
	padding: 0 60px 1.25rem 60px;
	text-align: left;
}
#faq2 .card .card-body {
	min-height: 1px;
	padding: 0 60px 1.25rem 60px;
	text-align: left;
}
  #faq .card .collapsing {
	background: #fff;
	line-height: 30px;
  }
  #faq2 .card .collapsing {
	background: #fff;
	line-height: 30px;
  }
  
  #faq .card .collapse {
	border: 0;
  }
  #faq2 .card .collapse {
	border: 0;
  }
  
  #faq .card .collapse.show {
	background: #FFF;
	line-height: 30px;
	color: #222;
}
  #faq2 .card .collapse.show {
	background: #FFF;
	line-height: 30px;
	color: #222;
}
  .sec-7 .heading {
	width: 100%;
}
.sec-5-img-1 {
	display: none;
}
.sec-3.sec-8 {
	padding: 53px 0 38px 0;
	background: #D9D9D9;
}
.sec-3.sec-8 .sec-3-inner {
	background: transparent;
	padding: 0;
	border: 0;
	border-radius: 0;
}
.sec-3.sec-8 .form-control::placeholder {
	color: #000;
	opacity: 1;
}
.sec-3.sec-8 .form-control {
    height: 52px;
    background: transparent;
    border-radius: 4px;
    margin: 12px 0;
    display: block;
    color: #000;
    padding: 10px 20px;
    border: 1px solid #00000052;
}
.sec-3.sec-8 .form-control:focus {
    color: #000000;
    background-color: transparent;
    border-color: #000;
    outline: 0;
    box-shadow: none;
}
.sec-3.sec-8 .form-control::placeholder {
		color: 00000052 !important;
	}
.sec-3.sec-8 .form-content h3 {
    color: #000;
    text-align: left;
    font-family: Manrope;
    font-size: 20px;
    font-weight: 700;
    line-height: 150%;
    opacity: 78%;
    margin-bottom: 20px;
    text-align: center;
}
.sec-3.sec-8 .sub-heading {
	margin: 0;
	font-family: Manrope;
	font-size: 32px;
	font-weight: 300;
	line-height: 150%;
	letter-spacing: -1px;
	text-align: left;
	color: #fff;
}
.sec-3.sec-8 .heading {
	text-align: left;
	color: #fff;
}
.sec-9 .heading {
	margin: 0 0 34px 0;
	text-align: left;
	font-family: Manrope;
	font-size: 32px;
	font-weight: 800;
	line-height: 40px;
	letter-spacing: -1px;
}
.sec-9 .sub-heading {
	text-align: left;
}
.sec-9 .pc-contact {
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	flex-wrap: wrap;
	gap: 40px 0;
}
.sec-9 .pc-contact-cont h4 {
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-weight: 600;
	line-height: 18px;
	text-align: center;
	margin: 0;
	color: #000;
	opacity: 70%;
}
.sec-9 .pc-contact-cont span {
	font-family: Plus Jakarta Sans;
	font-size: 11px;
	font-weight: 400;
	line-height: 18px;
	text-align: center;
	color: #000;
	opacity: 70%;
}
.sec-9 .pc-contact .pc-contact-block {
	display: flex;
	flex-direction: column;
	gap: 24px;
	flex: 0 0 25%;
	max-width: 25%;
	justify-content: center;
	align-items: center;
}
.bTfUEf span.monial-head {
    display: inline-grid;
    color: black;
}
.col-lg-4.col-md-6.col-sm-12.col-xs-12 {
    color: black;
}
.spans{
color:#ffffff !important;
}
.error {
	color: #c30000;
}
.add-contact-new {
	border: 1px solid #DFDFDF;
	background: #fff;
	padding: 32px 40px 60px;
	border-radius: 16px;
    position: relative;
}
.add-contact-new .form-content h3 {
	color: #000;
	text-align: left;
	font-family: Manrope;
	font-size: 20px;
	font-weight: 700;
	line-height: 150%;
	opacity: 78%;
	margin-bottom: 20px;
    text-align: center;
}
    .add-contact-new .form-control::placeholder {
	color: #000;
	opacity: 1;
}
    .add-contact-new .contact-form .btn {
  background: #1d4ed8;
}
.add-contact-new .contact-form .btn {
  background: #1D4ED8;
  border: 0;
  padding: 16px 42px;
  width: 100%;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin: 12px 0 0 0;
}
    .add-contact-new .form-group label {
	display: none;
}
    .add-contact-new .form-control {
	height: 52px;
	background: transparent;
	border-radius: 4px;
	margin: 12px 0;
	display: block;
	color: #000;
	padding: 10px 20px;
	border: 1px solid #00000052;
}
.sec-9 {
	padding: 120px 0;
}
.sec-5.sec-10 .row.mid {
	justify-content: center;
}
.sec-3.sec-8 .add-contact {
    border: 1px solid #DFDFDF;
    background: #fff;
    padding: 32px 40px 60px;
    border-radius: 16px;
}
.sec-3.sec-8.sec-11 .row {
	justify-content: center;
	gap: 60px;
}
.sec-3.sec-8.sec-11 .sub-heading {
	text-align: center;
}
.sec-3.sec-8.sec-11 .heading {
	text-align: center;
}
.sec-3.sec-8.sec-11 {
	background: #000;
	padding: 80px 0;
}
.sec-3.sec-8 .contact-form .btn {
	background: #1d4ed8;
}
.footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #121212;
	padding: 36px 56px;
}
.footer .foot.foot-2 {
	display: flex;
	gap: 25px;
}
.sec-5.sec-10 #faq2 .card .card-header .btn-header-link::after {
	content: "+";
	font-size: 30px;
	font-weight: 500;
	float: right;
	position: absolute;
	top: 20px;
	right: 25px;
	color: #1D4ED8;
	transform: rotate(45deg);
	left: inherit;
}
.sec-8-inner-block {
	margin-top: 80px;
}
.sec-5.sec-10 {
    padding: 0px 0 70px;
}
.sec-5.sec-10 #faq2 .card .card-header .btn-header-link.collapsed::after {
	content: "+";
	transform: rotate(0deg);
	color: #1D4ED8;
}
.sec-5.sec-10 #faq2 .card .card-header .btn-header-link {
	padding: 20px 60px 20px 0;
}
.sec-5.sec-10 #faq2 .card .card-body {
	min-height: 1px;
	padding: 0 60px 1.25rem 0px;
	text-align: left;
}
.foot.foot-middle a {
    text-decoration: none;
}
.sec-5.sec-10 #faq2 .card {
	margin-bottom: 0;
	border-left: 0px !important;
	border: 1px solid #0000001F;
	border-right: 0px !important;
	border-top: 0px !important;
}
.sec-5.sec-10 .in-faq-img {
    margin-right: 12px;
    height: 50px;
    width: 50px;
    object-fit: cover;
}
.sec-5.sec-10 #faq2 .card .card-header .btn-header-link.collapsed {
	background: #FFF;
}
.sec-3.sec-8.sec-11 .form-content h3 {
	text-align: left;
}
.feature-btn a {
    padding: 12px 18px;
    background: #1D4ED8;
    border-radius: 4px;
    font-family: Plus Jakarta Sans;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #fff;
    display: inline-block;
    margin-right: 10px;
}

.feature-btn {
    margin-top: 22px;
}

.feature-btn a:hover {
    background: black;
    text-decoration: none;
	color: #fff;
}
span.monial-head small {
    font-family: Plus Jakarta Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
}

span.monial-head {
    display: inline-grid;
}
.monial-accordian#faq2 .card .card-header .btn-header-link {
    display: flex;
    align-items: center;
}
.monial-accordian#faq2 .card .card-header .btn-header-link {
    color: #000000;
}
.monial-accordian#faq2 .card-body {
    font-family: Plus Jakarta Sans;
    font-size: 13px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: rgb(0 0 0 / 80%);
}
.sec-5-img img {
    max-width: 100%;
}
.mobile-slider-1, .mobile-slider-2{
	display: none;
}
.sec-3 .form-group label {
    display: none;
}
.pc-sec-4-img{
	display: block;
}
.mobile-sec-4-img{
	display: none;
}
// .sec-7-btnrow {
//     display: none;
// }
.sec-5-img-mobile {
    display: none;
}
.foot.foot-middle a {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 17.76px;
    text-align: left;
    color: #fff;
    margin: 0 30px;
}
// section.sec-7 .row.sec-7-btnrow {
//     display: none;
// }
.sec-5 .sec-5-img {
	width: 100%;
}
.sec-5 .sec-5-img-mobile img {
	width: 100%;
}
.sec-3.sec-8 .sec-3-inner {
	margin: 0;
}
.banner .banner-para {
	max-width: 80%;
}
.sec-3.sec-8 {
	background: url(${backgroundImage});
	background-size: cover;
	background-repeat: no-repeat;
    background-position: center;
}
 .banner {
	background: url(${backgroundImage1});
	background-size: cover;
	background-repeat: no-repeat;
    background-position: center;
}
    .sec-3.sec-8.sec-8-new{
    background: url(${backgroundImage2});
	background-size: cover;
	background-repeat: no-repeat;
    background-position: center;
    }
   
.sec-9 .pc-contact-icon img {
	width: 64px;
	height: auto;
}
.bannering-img.bannering-img-none{
	display: none;
}
.banner-heading::before {
	position: absolute;
	content: "";
	background: url(${ticks}) center center no-repeat;
	background-size: cover;
	height: 7px;
	width: 245px;
	left: 17px;
	right: 0;
	/* margin: auto; */
	top: 48px;
}
.sec-3 span.error, .sec-3.sec-8 span.error {
    display: flex;
    justify-content: left;
    color: #c30000;
}
.card-header {
    background-color: transparent !important;
}
br.next-line {
    display: none;
}
.monial-accordian#faq2 .card .card-header .btn-header-link {
    text-align: left !important;
}
.stick-foot {
	position: sticky;
	bottom: 0;
	background: #fff;
	padding: 10px;
	font-size: 16px;
	display: none;
	text-align: center;
	font-weight: 700;
    z-index: 999;
}
.stick-foot a {
	padding: 8px 16px;
	float: left;
	border: 1px solid #1d4ed8;
	color: #fff;
	font-weight: 700;
	border-radius: 4px;
	background: #1d4ed8;
}
    .sec-3.new-sec-addd{
    display: none;
    }
  @media (min-width: 1366px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl {
	  max-width: 1180px !important;
	}
  }
  /* @media (min-width: 576px) {
	select.form-control.ble-color {
		background: #1d4ed8 !important;
	}
  } */

.heading.heading-addd {
	text-transform: inherit;
}
/* thankyou css */

/* footer */
.footer_infoslinks {
    text-align: left;
}
.footer_infoslinks ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer_infoslinks ul li a {
    color: #fff;
    font-size: 16px;
    line-height: 23px;
}

.footer_infoslinks ul li {
    margin: 0 0 14px 0;
}.footer_centerterms {
    text-align: center;
    display: block;
}.footer_centerterms a {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    margin: 0 10px;
}.footer_centerterms {
    display: none;
}

/* footer */
.sucess_btn_expolor a {
    width: auto;
    border-radius: 8px;
    height: 54px;
    background: #325ACC;
    transition: all 0.5s ease;
    border-color: #325ACC;
    display: inline-block;
    color: #fff;
    font-size: 18px;
    line-height: 54px;
    padding: 0 65px;
    font-family: "Plus Jakarta Sans", sans-serif !important;
    transition: all 0.5s ease;
    margin: 10px 0 90px 0;
    position: relative;
    z-index: 1;
    text-decoration: none;
}


.sucess_btn_expolor a:after{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #153aa1;
    height: 0;
    width: 100%;
    content: "";
    z-index: -1;
    border-radius: 4px;
    transition: all 0.7s ease;
}

.sucess_btn_expolor a:hover:after {
    height: 100%;
}

.sucess_btn_expolor a:hover {
    background: #153aa1;
    color: #fff;
}
.sucess_btn_expolor a:hover {
    color: #fff;
}


.sucess_right_tick span {
    background: none;
    display: block;
    text-align: center;
    margin: 60px auto 0 auto;
}

.sucess_right_tick {
    text-align: center;
    padding-top: 20px;
}
.dotpe_popup.sucess_popinfos {
	margin-bottom: 84px;
    text-align: center;
    font-family: "Plus Jakarta Sans", sans-serif !important;
    flex-direction: column;
}
.sucess_popinfos h3 {
    font-size: 34px;
    line-height: 42px;
    font-weight: 700;
    text-align: center;
    margin: 20px auto;
    max-width: 100%;
    color: #000;
}
span.up-head {
    color: white;
}
.sucess_popinfos p {
    font-size: 22px;
    line-height: 26px;
    color: #000;
    max-width: 100%;
    margin: 0px auto 30px auto;
}

.dotpe_popup.sucess_popinfos {
    text-align: center;
    font-family: "Plus Jakarta Sans", sans-serif !important;
    flex-direction: column;
}

/* thankyou css */



@media (max-width: 1600px){
    .banner-heading::before {
        position: absolute;
        content: "";
        background: url(${ticks}) center center no-repeat;
        background-size: cover;
        height: 7px;
        width: 245px;
        left: 17px;
        right: 0;
        top: 64px;
    }
}
@media (max-width: 1366px){
    .banner-heading::before {
        position: absolute;
        content: "";
        background: url(${ticks}) center center no-repeat;
        background-size: cover;
        height: 7px;
        width: 245px;
        left: 17px;
        right: 0;
        top: 48px;
    }
}
  @media (max-width: 1299px) {
	.banner-heading {
		font-size: 32px;
		line-height: 44px;
	}
	.banner-heading::before {
		position: absolute;
		content: "";
		background-size: contain;
		height: 7px;
		width: 223px;
		left: 4px;
		right: 0;
		/* margin: auto; */
		top: 68px;
	}
	.sec-3-inner {
		margin: 0px 50px;
	}
	.banner-para {
		margin: 30px 0 48px;
	}
	.heading {
		font-size: 44px;
		line-height: 52px;
	}
	.sub-heading {
		font-size: 20px;
	}
  }

  @media (max-width: 1199px) {
	.banner-heading {
		font-size: 28px;
		line-height: 40px;
	}
	.sec-3-inner {
		margin: 0px 30px;
	}
	.banner-para {
		margin: 30px 0 48px;
	}
	.banner {
		padding: 80px 0 130px;
	}
	.sec-2 {
		padding: 80px 0;
	}
	.sec-4 {
		padding: 100px 0 100px 0;
	}
	.sec-7 {
		padding: 82px 0 0 0;
	}
	.sub-heading {
		font-size: 18px;
	}
	.heading {
		font-size: 40px;
		line-height: 48px;
	}
  }


  @media (max-width: 1099px) {
.banner-heading {
    font-size: 21px;
    line-height: 32px;
}
	.banner-heading::before {
		position: absolute;
		content: "";
		background-size: contain;
		height: 7px;
		width: 150px;
		left: 16px;
		right: 0;
		/* margin: auto; */
		top: 30px;
	}
	.banner-para {
		margin: 20px 0 36px;
	}
	.banner {
		padding: 80px 0 110px;
	}
	.sec-2 {
		padding: 60px 0;
	}
	.sec-4 {
		padding: 80px 0 80px 0;
	}
	.sec-7 {
		padding: 60px 0 0 0;
	}
	.sub-heading {
		font-size: 16px;
	}
	.heading {
		font-size: 36px;
		line-height: 44px;
	}
	.full-img {
		margin: 31px 0 31px;
	}
  }

  @media (max-width: 991px) {
      .sec-7 .sec-7-block .sec-7-img img {
        height: 30px;
        width: auto;
    }
            .stick-foot {
	display: block;
}
.stick-foot {
	display: block;
	display: flex;
	justify-content: center;
	box-shadow: 0 0 10px 1px #d0d0d0a3;
}
    .header {
        position: relative;
    }
    .sec-7 .sec-1-block.sec-7-img-ch {
        flex: 0 0 46%;
        max-width: auto;
    }
    .banner {
        margin-top: 0;
    }
	.banner {
		padding: 50px 0 34px;
	}
	.banner-image {
    z-index: 0;
    }
	.bannering-img.bannering-img-block {
		display: none;
	}
	.bannering-img.bannering-img-none {
		display: block;
		margin: 35px 0 42px;
	}
	.sec-3-inner {
		margin: 0;
	}
	.ban-bt {
    position: relative;
    }
	.banner-heading {
		text-align: center;
        margin: 0 22px;
	}
	.sec-3-inner .row-none {
		display: none;
	}
	.form-content h3 {
		text-align: center;
	}
	.form-content h2 {
		text-align: center;
	}
    
	.banner-heading .up-head {
		display: block;
	}
    
	.form-content h4 {
		text-align: center;
	}
	.form-content-today {
		padding: 0;
	}
	.banner-para {
    font-size: 12px;
    text-align: center;
    margin: 24px 42px;
}
	.ban-first span {
		font-size: 13px;
		margin: auto;
	}
	.ban-bt {
    justify-content: center;
    margin: 8px 0 20px;
}
    .banner-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0px !important;
        flex-wrap: nowrap;
    }

	.banner-mobile {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 57px;
	}
	.banner-mobile .ban-last {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	.banner-mobile .ban-last img {
		margin-bottom: 8px;
	}
	.sec-2 .para {
		font-size: 16px;
		margin: 0 15px 32px;
	}
	.sec-2 {
		padding: 52px 0;
	}
	.sec-7 .sec-7-block {
		gap: 48px 16px;
		flex-wrap: wrap;
	}
	.sec-1-block {
		max-width: 30%;
		flex: 0 0 30%;
        text-align: center;
        padding: 16px 10px 0;
	}
	.sec-7 h3 span {
		display: inline;
	}
	.banner-heading {
		position: relative;
	}
	.banner-heading:before {
		position: absolute;
		content: "";
		background: url(${ticks}) center center no-repeat;
		background-size: contain;
		height: 7px;
		width: 197px;
		left: 0;
		right: 0;
		margin: auto;
		top: 30px;
	}
	.banner-heading .up-head {
		margin-top: 5px;
	}
	.sec-4-inner-ch {
		margin-top: 0;
	}
	.sec-4-inner {
		margin-bottom: 40px;
	}
	.feature-btn a {
		margin-bottom: 5px;
	}
	#faq .card .card-body {
		padding: 0 25px 1.25rem 30px;
	}
	#faq .card .card-header .btn-header-link {
		padding: 20px 25px 40px 30px ;
	}
	#faq .card .card-header .btn-header-link::after {
		left: 5px;
	}
	.sec-3.sec-8 .heading span {
		display: inline-block;
	}
	span.pc-contact-icon img {
		max-width: 40px;
	}
	.sec-5 .subheading {
		font-size: 24px;
	}
	.sec-3-inner {
		padding: 60px 40px;
	}
	.sec-5 {
		padding: 90px 0 60px;
	}
	.sec-8-inner-block {
		margin-top: 20px;
		margin-bottom: 40px;
	}
	.sec-9 {
		padding-top: 70px;
		padding-bottom: 30px;
	}
	.sec-5.sec-10 #faq2 .card .card-body {
		padding-right: 0;
	}
  }
  @media (max-width:767px){
	.foot.foot-middle a {
		display: inline-block;
		margin: 0 10px;
	}
         .banner {
	background: url(${backgroundImage3});
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}
        .banner::before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: #000;
	opacity: 0.7;
}
    .sec-3.sec-8.sec-8-new::before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: #000;
	opacity: 0;
}
    .sec-3.sec-8.sec-8-new{
    background: url(${backgroundImage4});
	background-size: cover;
	background-repeat: no-repeat;
    }
            .sec-3.new-sec-addd{
    display: block;
    }
    .sec-3.new-sec-addd {
	display: block;
	padding: 50px 0 0;
}
.sec-3.new-sec-addd .sec-3-inner {
	padding: 24px 24px 30px;
	border: 1px solid #DEDEDE;
}
	.sec-5 .subheading {
		font-size: 20px;
	}
	.sec-5-img-mobile {
		display: block;
		margin-top: 24px;
	}
	.sec-5-img {
		display: none;
	}
	.iyyBmJ .banner-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0px;
        flex-wrap: nowrap;
    }
    .sec-3 {
        background: #ffffff;
        padding:0;
    }
        .sec-2 .container-fluids {
        display: flex;
        flex-direction: column;
        gap: 34px;
    }
        .sec-2 p.para {
	margin: 0 auto 20px auto !important;
	max-width: 270px;
}
  }
  @media (max-width:576px){
	.banner-para {
		font-weight: 400;
		line-height: 24px;
		opacity: 1;
	}
	.sec-3.sec-8 .form-control {
    color: #fff !important;
}
	br.next-line {
    display: block;
}
.sucess_popinfos h3 {
    font-size: 24px;
    line-height: 32px;
}
	.form-content h2 span {
    display: block;
}
.sucess_popinfos p {
    font-size: 16px;
    max-width: 210px;
}
	.bannering-img.bannering-img-none {
    margin: 35px 8px 28px;
}
.sucess_right_tick span img {
    width: 50px;
}
	.sec-3.sec-8 {
		position: relative;
		background-position: top center;
	}
	.sec-3.sec-8::before{
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background: #000;
		opacity: 0.7;
	}
	.footer {
		background: #000;
		border-top: 1px solid #383838;
	}
	.ban-first span {
		line-height: 28px;
	}
	.ban-last {
		font-size: 10px;
		font-weight: 600;
	}
	.ban-last span {
    font-size: 8px;
    line-height: 16px;
}
	.sec-2 .para {
		margin: 0 32px 32px;
		line-height: 23px;
	}
	.desktop-slider{
		display: none;
	}
	.mobile-slider-1, .mobile-slider-2{
		display: block;
	}
	.owl-slider.mobile-slider-2 {
		margin-top: 12px;
	}
	.sec-3-inner {
		padding-top: 0;
		padding-left: 15px;
		padding-right: 15px;
		background: none;
		border: none;
		padding-bottom: 52px;
	}
	.form-content h3, .form-content h2 {
		color: #000;
	}
	.form-content h4 {
		display: none;
	}
	.sec-3 .form-group label {
		/* display: block; */
		font-family: Plus Jakarta Sans;
		font-size: 14px;
		font-weight: 600;
		line-height: 26px;
		letter-spacing: -0.10000000149011612px;
		text-align: left;
		margin-bottom: 12px;
	}
	.sec-3 .form-control {
		border-radius: 8px;
		background: #FAFBFF;
		border: 1px solid #E7E9ED;
	}
	.form-content h3 {
    font-family: Plus Jakarta Sans;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
}
.sec-2 {
    padding: 52px 0 64px;
}
	.form-content h2 {
		font-family: Plus Jakarta Sans;
		font-size: 24px;
		font-weight: 700;
		line-height: 32px;
		text-align: center;
		margin-bottom: 24px;
	}
	.sec-3 .contact-form .btn {
		background: #1D4ED8;
		font-size: 16px;
	}
	.sec-4 .ban-bt {
		margin-bottom: 0;
		margin-top: 0;
	}
	section.sec-4 {
		background: #F3F3F3;
		padding-top: 52px !important;
		padding-bottom: 52px !important;
		padding-left: 9px !important;
		padding-right: 9px !important;
        margin:0;
	}
	section.sec-4 .sub-heading {
		display: none;
	}
	.sec-4 .heading {
		font-family: Plus Jakarta Sans;
		font-size: 24px;
		font-weight: 700;
		line-height: 32px;
		text-align: center;
		max-width: 275px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 0;
	}
	.sec-4 .heading span {
		display: inline;
	}
	.pc-sec-4-img{
		display: none;
	}
	.mobile-sec-4-img{
		display: block;
	}
	.sec-4-inner h3 {
    margin-bottom: 12px;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
}
.sub-heading {
    margin: 0 0 8px 0;
}
	.sec-4-inner p {
		font-family: Plus Jakarta Sans;
		font-size: 12px;
		line-height: 20px;
	}
section.sec-5.sec-10 .heading {
    margin: 8px auto 16px;
}
section.sec-5 h2.sub-heading {
    max-width: 265px;
    margin: auto;
}
section.sec-5.sec-10 h2.sub-heading br {
    display: none;
}
#faq .card .card-header .btn-header-link.collapsed {
    padding: 20px 45px 20px 24px;
}
	.sec-4-inner {
		margin-bottom: 36px;
	}
	.sec-4-inner-ch {
		margin-top: 0;
	}
	.sec-4 .row-align {
		margin-top: 31px;
	}
	.col-lg-4.col-md-6.col-sm-12.col-xs-12:last-child .sec-4-inner {
		margin-bottom: 0;
	}
	.mobile-space-0 {
		padding: 0;
	}
	.sec-4  .ban-bt a {
		font-size: 16px;
	}
	.sec-7 {
		padding-top: 52px;
		padding-bottom: 52px;
	}
	.sec-7 .heading {
		font-family: Plus Jakarta Sans;
		font-size: 24px;
		font-weight: 700;
		line-height: 32px;
		text-align: center;
		max-width: 275px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 0;
	}
	.sec-7 .heading span{
		display: inline;
	}
	.sec-1-block {
		max-width: 46%;
		flex: 0 0 46%;
	}
	.sec-7 h3 {
		font-family: Plus Jakarta Sans;
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		text-align: center;
		margin-bottom: 15px;
	}
	.sec-7 p {
		font-family: Plus Jakarta Sans;
		font-size: 10px;
		font-weight: 500;
		line-height: 20px;
		text-align: center;
        display:block;
	}
	.sec-7-img img {
		max-height: 31px;
	}
	.sec-7-block {
		padding-left: 20px !important;
		padding-right: 20px !important;
	}
	.excelent-cont {
		display: none;
	}
	.sec-7-btnrow {
		display: block;
	}
	.sec-7-btnrow .ban-bt {
		margin-top: 0;
		margin-bottom: 0;
	}
	.sec-7-btnrow .ban-bt a {
		font-size: 16px;
	}
	.sec-5 {
		padding-top: 52px;
		padding-bottom: 52px;
	}
	.sec-5 .heading {
		font-family: Plus Jakarta Sans;
		font-size: 24px;
		font-weight: 700;
		line-height: 32px;
		letter-spacing: -1px;
		text-align: center;
		max-width: 270px;
		margin-left: auto;
		margin-right: auto;
	}
	.sec-5 .heading span {
		display: inline;
	}
	.sec-5 .subheading {
		font-family: Plus Jakarta Sans;
		font-size: 14px;
		font-weight: 500;
		line-height: 24px;
		text-align: center;
		color: rgb(0 0 0 / 51%);
		max-width: 300px;
		margin-left: auto;
		margin-right: auto;
	}
	#faq .card .card-header .btn-header-link {
		font-family: Plus Jakarta Sans;
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		text-align: left;
		padding: 24px 45px 24px 24px;
		color: #000;
	}
	#faq .card {
		border: none;
		margin-bottom: 0;
	}
	#faq .card .card-header .btn-header-link::after {
		left: auto;
		right: 0;
	}
	#faq .card .card-body {
		padding: 0;
		font-family: Plus Jakarta Sans;
		font-size: 13px;
		font-weight: 500;
		line-height: 21px;
		text-align: left;
		padding-bottom: 24px;
		padding-left: 24px;
		padding-right: 24px;
	}
	.feature-btn a {
		padding: 6px 13px;
		font-size: 12px;
		margin-right: 5px;
	}
	#faq .card .card-header .btn-header-link.collapsed {
		background: none;
		
	}
	#faq .card .card-header {
		background: none;
		border-top: 1px solid #D9D9D9;
		border-bottom: 1px solid #D9D9D9;
	}
	/* .sec-5-img {
		display: none;
	} */
	.sec-5-img-mobile img {
		max-width: 100%;
	}
	/* .sec-5-img-mobile {
		display: block;
		margin-top: 24px;
	} */
	/* #faq .card:first-child .card-header .btn-header-link {
		padding-top: 0;
	} */
	#faq .card:first-child .card-header  {
		border-top: 0;
	}
	.sec-5 .container {
		padding-left: 0;
		padding-right: 0;
	}
	
	.sec-5 .container .col-lg-7.col-md-6.col-sm-12.col-xs-12 {
		padding-left: 0;
		padding-right: 0;
	}
	#faq .card .card-header .btn-header-link::after {
    right: 18px;
    top: 24px;
}
	.sec-3.sec-8 {
		
		padding-top: 52px;
		padding-bottom: 52px;
	}
	.sec-3.sec-8 .sub-heading {
    color: #fff;
    font-family: Plus Jakarta Sans;
    font-size: 13px;
    font-weight: 500;
    /* line-height: 35px; */
    text-align: center;
    letter-spacing: normal;
    margin-bottom: 8px;
}
	.sec-8-inner-block {
		margin-top: 0;
	}
	.sec-3.sec-8 .heading {
		font-family: Plus Jakarta Sans;
		font-size: 24px;
		font-weight: 700;
		line-height: 32px;
		text-align: center;
		color: #fff;
		max-width: 250px;
		margin: 0 auto 24px auto;
	}
	.sec-3.sec-8 .heading span{
		display: inline;
	}
	.sec-3.sec-8 .form-content h3 {
		display: none;
	}
	.sec-3.sec-8 .add-contact {
		padding: 0 15px;
		background: none;
		border: none;
	}
	.sec-3.sec-8 .form-group label {
		color: #FFFFFF;
	}
	.sec-3.sec-8 .form-control {
		border: 1px solid rgb(231 233 237 / 28%);
		color: #fff;
		border-radius: 8px;
	}
	.sec-3.sec-8 .form-control::placeholder {
		color: rgba(255, 255, 255, 0.629)  !important;
	}
	.sec-3.sec-8 .form-control:focus {
		border: 1px solid #fff;
	}
	.sec-3.sec-8 .form-control::placeholder {
		color: #fff;
	}
	.sec-9 {
		padding-top: 52px;
		padding-bottom: 0px;
	}
	.sec-9 .sub-heading {
		font-family: Manrope;
		font-size: 16px;
		font-weight: 500;
		text-align: center;
	}
	.sec-9 .heading {
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    max-width: 230px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
}
	h2.sub-heading.sec9-again {
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    max-width: 300px;
    color: #212529;
    margin-bottom: 37px;
    margin-left: auto;
    margin-right: auto;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}
	span.pc-contact-icon img {
    max-width: inherit;
    max-height: 36px;
    width: auto !important;
}
.sec-9 .pc-contact .pc-contact-block {
    gap: 10px;
}
	.sec-9 .pc-contact-cont h4 {
		font-size: 12px;
		font-weight: 700;
	}
	.sec-9 .pc-contact-cont h4 span {
		font-size: 11px;
		font-weight: 400;
	}
	.sec-7 .sec-7-block {
		padding-bottom: 48px;
	}
	.sec-9 .pc-contact {
		gap: 0;
		justify-content: center;
		align-items: baseline;
		border-top: 1px solid rgb(0 0 0 / 12%);
		/* border-bottom: 0; */
	}
	.sec-9 .pc-contact .pc-contact-block {
		flex: 0 0 50%;
		max-width: 50%;
		border-bottom: 1px solid rgb(0 0 0 / 12%);
		min-height: 94px;
		padding-bottom: 13px;
		padding-top: 22px;
	}
	span.pc-contact-cont {
    min-height: 32px;
}
	.sec-9 .pc-contact .pc-contact-block:nth-child(even) {
		border-left: 1px solid rgb(0 0 0 / 12%);
	}
	/* .mobile-container-pading-zero{
		padding-left: 0 !important;
		padding-right: 0 !important;
	} */
	.padding-zero {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.sec-5.sec-10 {
    padding-top: 64px;
    padding-bottom: 0px;
}
	section.sec-5.sec-10 .heading {
		font-family: Manrope;
		font-size: 24px;
		font-weight: 800;
		line-height: 34px;
		text-align: center;
		max-width: 300px;
	}
	.sec-5.sec-10 .in-faq-img {
		max-width: 50px;
		max-height: 50px;
	}
	.sec-5.sec-10 #faq2 .card .card-header a {
		padding-left: 26px !important;
		padding-right: 45px !important;
		padding-top: 24px!important;
		padding-bottom: 24px!important;
	}
	.sec-5.sec-10 #faq2 .card .card-body {
		padding-left: 26px;
		padding-right: 26px;
	}
	.sec-5.sec-10 #faq2 .card .card-header .btn-header-link.collapsed::after {
		top: 24px;
	}
	.sec-5.sec-10 #faq2 .card .card-header .btn-header-link::after {
		top: 24px;
	}
	.sec-5.sec-10 #faq2 span.monial-head {
		display: inline-grid;
		font-family: Plus Jakarta Sans;
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		text-align: left;
	}
	.sec-5.sec-10 #faq2 .btn-header-link.collapsed span.monial-head {
		font-weight: 500;
	}
	.sec-5.sec-10 .row.mid {
		margin-top: 52px;
	}
	.sec-3.sec-8.sec-11 {
		padding-top: 52px;
		padding-bottom: 52px;
		border-bottom: 1px solid #383838;
	}
	.sec-3.sec-8.sec-11 .row {
		gap: 0;
	}
	.footer {
		flex-direction: column;
		padding: 46px 46px;
	}
	.foot.foot-middle {
    padding: 37px 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    max-width: 268px;
}
.foot.foot-2 a img {
    width: auto;
    height: 19px;
}
	.foot.foot-middle a:first-child {
		margin-left: 0;
	}
	.foot.foot-middle a:last-child {
		margin: 0;
	}
	select.form-control.ble-color {
		color: #000 !important;
	}
	.sec-3 .form-control:focus {
		border-color: #000;
	}
	.sec-3 .form-control {
		height: 50px;
	}
	.sec-5 .row.mid {
		margin: 40px 0 0 0;
	}
	section.sec-7 .row.sec-7-btnrow {
		display: block;
	}
	
	
  }
  @media (max-width:480px){
	.foot.foot-middle a {
		font-size: 13px;
	}
  }
`;
const Restaurantpossoftware = () => {

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "auto",
        });
      };

    const location = useLocation();
    // const [selectedImage, setSelectedImage] = useState(require("../../imagesrista/1_Billing.webp"));
    // const [selectedImage, setSelectedImage] = useState(require("../../imagesrista/1_Billing.webp"));
    const [selectedImage, setSelectedImage] = useState({
        src: require('../../imagesrista/1_Billing.webp'),
        alt: 'Rista restaurant pos dine in order taking screen',
        title:
            'Rista restaurant pos dine in order taking screen',
    });

    // const handlecardClick = (imagesrc) => {
    //     setSelectedImage(imagesrc)

    // }
    const handlecardClick = (imageSrc, title, alt) => {
        setSelectedImage({
            src: imageSrc,
            title: title,
            alt: alt,
        });
    };
    const navigate = useNavigate();

    const [showThankYou, setShowThankYou] = useState(false);
    const [showThankYou1, setShowThankYou1] = useState(false);
    const [showThankYou2, setShowThankYou2] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobileNumber: '',
        city: '',
        restaurantName: '',

        name1: '',
        email1: '',
        mobileNumber1: '',
        city1: '',
        restaurantName1: '',

        name2: '',
        email2: '',
        mobileNumber2: '',
        city2: '',
        restaurantName2: '',
    });

    const [formErrors, setFormErrors] = useState({});
    const [hasError, setHasError] = useState(false);
    const [emailSuggestions, setEmailSuggestions] = useState([]);
    const [firstFormId, setFirstFormId] = useState('');
    const formClassName = hasError ? 'form-error form-group' : 'form-group';

    const domains = ['gmail.com', 'yahoo.com', 'outlook.com'];

    const validateForm = () => {
        const errors = {};
        let formIsValid = true;
        const nameRegex = /^[A-Za-z\s]+$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const numberRegex = /^\d+$/;

        if (!formData.name2 || !formData.name2.trim()) {
            formIsValid = false;
            errors.name2 = 'Name is required';
        } else if (formData.name2.trim().length < 3) {
            formIsValid = false;
            errors.name2 = 'Name should have at least 3 characters';
        } else if (formData.name2.trim().length > 30) {
            formIsValid = false;
            errors.name2 = 'Name should have at max 30 characters';
        } else if (!nameRegex.test(formData.name2.trim())) {
            formIsValid = false;
            errors.name2 = 'Name should contain only letters and spaces';
        }

        if (!formData.email2 || !formData.email2.trim()) {
            formIsValid = false;
            errors.email2 = 'Email is required';
        } else if (!emailRegex.test(formData.email2)) {
            formIsValid = false;
            errors.email2 = 'Invalid email address';
        }

        if (!formData.city2 || !formData.city2.trim()) {
            formIsValid = false;
            errors.city2 = 'City is required';
        }
        if (!formData.restaurantName2 || !formData.restaurantName2.trim()) {
            formIsValid = false;
            errors.restaurantName2 = 'RestaurantName is required';
        }

        if (!formData.mobileNumber2 || !formData.mobileNumber2.trim()) {
            formIsValid = false;
            errors.mobileNumber2 = 'Mobile Number is required';
        } else if (!numberRegex.test(formData.mobileNumber2)) {
            formIsValid = false;
            errors.mobileNumber2 = 'Invalid mobile number';
        } else if (formData.mobileNumber2.trim().length !== 10) {
            formIsValid = false;
            errors.mobileNumber2 = 'Mobile number must be exactly 10 digits';
        } else {
            const firstDigit = formData.mobileNumber2.trim().charAt(0);
            if (!['6', '7', '8', '9'].includes(firstDigit)) {
                formIsValid = false;
                errors.mobileNumber2 = 'Mobile number must start with 6, 7, 8, or 9';
            }
        }

        setFormErrors(errors);
        setHasError(!formIsValid);

        return formIsValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        let newValue = value;
        if (name === 'name') {
            if (value === '' || /^[^\s]/.test(value)) {
                newValue = value;
            } else {
                newValue = value.trimStart();
            }
        } else if (name === 'mobileNumber') {
            newValue = value.replace(/\D/g, '');

            if (newValue.length > 10) {
                newValue = newValue.slice(0, 10);
            }
        } else if (name === 'email') {
            if (value.includes('@')) {
                const [localPart, domainPart] = value.split('@');
                setEmailSuggestions(
                    domains
                        .filter((domain) => domain.startsWith(domainPart))
                        .map((domain) => `${localPart}@${domain}`)
                );
            } else {
                setEmailSuggestions([]);
            }
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: newValue,
        }));
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };
    const resetForms = () => {
        setFormData({
            name2: '',
            email2: '',
            mobileNumber2: '',
            city2: '',
            restaurantName2: '',
            incomeSource2: '',
            role2: ''
        });
    };
    const handleSumbit2 = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            // const apiUrl = 'https://report-api.stage3.dotnu.co/api/merchant/external/enquiry';
             const apiUrl = process.env.REACT_APP_FORM_ONE;
            const combinedData = {
              name: formData.name2,
              phone: formData.mobileNumber2,
              email: formData.email2,
              city: formData.city2,
              businessName: formData.restaurantName2,
              source: location.pathname,
            };
            localStorage.setItem('mobile', formData.mobileNumber2);

            try {
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json; charset=utf-8',
                    },
                    body: JSON.stringify(combinedData),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setFirstFormId(data.id);
                resetForms();
                // Navigate("/thankyou");
                navigate('/thankyou');
                // setShowThankYou2(true);
                // console.log("Inserted Data:", combinedData);
                console.log("///////////////////", data)
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };
    const handleChanges = (e) => {
        const { name, value } = e.target;
        let newValue = value;

        if (name === 'mobileNumber2') {
            newValue = value.replace(/\D/g, '');

            if (newValue.length > 10) {
                newValue = newValue.slice(0, 10);
            }
        } else if (name === 'email2') {
            if (value.includes('@')) {
                const [localPart, domainPart] = value.split('@');
                setEmailSuggestions(
                    domains
                        .filter((domain) => domain.startsWith(domainPart))
                        .map((domain) => `${localPart}@${domain}`)
                );
            } else {
                setEmailSuggestions([]);
            }
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: newValue,
        }));
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };
    const handleChangess = (e) => {
        const { name, value } = e.target;
        let newValue = value;

        if (name === 'mobileNumber1') {
            newValue = value.replace(/\D/g, '');

            if (newValue.length > 10) {
                newValue = newValue.slice(0, 10);
            }
        } else if (name === 'email1') {
            if (value.includes('@')) {
                const [localPart, domainPart] = value.split('@');
                setEmailSuggestions(
                    domains
                        .filter((domain) => domain.startsWith(domainPart))
                        .map((domain) => `${localPart}@${domain}`)
                );
            } else {
                setEmailSuggestions([]);
            }
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: newValue,
        }));
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    // 2nd forms
    const validateFormss = () => {
        const errors = {};
        let formIsValid = true;
        const nameRegex = /^[A-Za-z\s]+$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const numberRegex = /^\d+$/;

        // Validate Name
        if (!formData.name1 || !formData.name1.trim()) {
            formIsValid = false;
            errors.name1 = 'Name is required';
        } else if (formData.name1.trim().length < 3) {
            formIsValid = false;
            errors.name1 = 'Name should have at least 3 characters';
        } else if (formData.name1.trim().length > 30) {
            formIsValid = false;
            errors.name1 = 'Name should have at max 30 characters';
        } else if (!nameRegex.test(formData.name1.trim())) {
            formIsValid = false;
            errors.name1 = 'Name should contain only letters and spaces';
        }

        // Validate Email
        if (!formData.email1 || !formData.email1.trim()) {
            formIsValid = false;
            errors.email1 = 'Email is required';
        } else if (!emailRegex.test(formData.email1)) {
            formIsValid = false;
            errors.email1 = 'Invalid email address';
        }

        // Validate Mobile Number
        if (!formData.mobileNumber1 || !formData.mobileNumber1.trim()) {
            formIsValid = false;
            errors.mobileNumber1 = 'Mobile Number is required';
        } else if (!numberRegex.test(formData.mobileNumber1)) {
            formIsValid = false;
            errors.mobileNumber1 = 'Invalid mobile number';
        } else if (formData.mobileNumber1.trim().length !== 10) {
            formIsValid = false;
            errors.mobileNumber1 = 'Mobile number must be exactly 10 digits';
        } else {
            const firstDigit = formData.mobileNumber1.trim().charAt(0);
            if (!['6', '7', '8', '9'].includes(firstDigit)) {
                formIsValid = false;
                errors.mobileNumber1 = 'Mobile number must start with 6, 7, 8, or 9';
            }
        }
        // if (!formData.featuresLookingFor || !formData.featuresLookingFor.trim()) {
        //     formIsValid = false;
        //     errors.featuresLookingFor = 'RestaurantName is required';
        // }

        if (!formData.city1 || !formData.city1.trim()) {
            formIsValid = false;
            errors.city1 = 'City is required';
        }
        // Validate Restaurant Name
        if (!formData.restaurantName1 || !formData.restaurantName1.trim()) {
            formIsValid = false;
            errors.restaurantName1 = 'Restaurant Name is required';
        }

        // // Validate Number of Stores
        // if (!formData.numberOfStores || formData.numberOfStores === '0') {
        //     formIsValid = false;
        //     errors.numberOfStores = 'Number of Stores is required';
        // }

        setFormErrors(errors);
        setHasError(!formIsValid);

        return formIsValid;
    };
    const resetForms2 = () => {
        setFormData({
            name1: '',
            email1: '',
            mobileNumber1: '',
            city1: '',
            restaurantName1: '',
            numberOfStores1: '',
            featuresLookingFor1: ''
        });
    };
    const handleSumbit = async (e) => {
        e.preventDefault();
        if (validateFormss()) {
            // const apiUrl = 'https://report-api.stage3.dotnu.co/api/merchant/external/enquiry';
            const apiUrl = process.env.REACT_APP_FORM_ONE;
            const combinedData = {
              name: formData.name1,
              phone: formData.mobileNumber1,
              email: formData.email1,
              city: formData.city1,
              businessName: formData.restaurantName1,
              source: location.pathname,
            };
            localStorage.setItem('mobile', formData.mobileNumber1);

            try {
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json; charset=utf-8',
                    },
                    body: JSON.stringify(combinedData),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setFirstFormId(data.id);

                resetForms2();
                // Navigate("/thankyou");
                navigate('/thankyou');
                // setShowThankYou1(true);
                // console.log("Inserted Data:", combinedData);
                console.log("///////////////////", data)
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    // 3rd forms
    const validateForms = () => {
        const errors = {};
        let formIsValid = true;
        const nameRegex = /^[A-Za-z\s]+$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const numberRegex = /^\d+$/;

        // Validate Name
        if (!formData.name || !formData.name.trim()) {
            formIsValid = false;
            errors.name = 'Name is required';
        } else if (formData.name.trim().length < 3) {
            formIsValid = false;
            errors.name = 'Name should have at least 3 characters';
        } else if (formData.name.trim().length > 30) {
            formIsValid = false;
            errors.name = 'Name should have at max 30 characters';
        } else if (!nameRegex.test(formData.name.trim())) {
            formIsValid = false;
            errors.name = 'Name should contain only letters and spaces';
        }

        // Validate Email
        if (!formData.email || !formData.email.trim()) {
            formIsValid = false;
            errors.email = 'Email is required';
        } else if (!emailRegex.test(formData.email)) {
            formIsValid = false;
            errors.email = 'Invalid email address';
        }

        // Validate Mobile Number
        if (!formData.mobileNumber || !formData.mobileNumber.trim()) {
            formIsValid = false;
            errors.mobileNumber = 'Mobile Number is required';
        } else if (!numberRegex.test(formData.mobileNumber)) {
            formIsValid = false;
            errors.mobileNumber = 'Invalid mobile number';
        } else if (formData.mobileNumber.trim().length !== 10) {
            formIsValid = false;
            errors.mobileNumber = 'Mobile number must be exactly 10 digits';
        } else {
            const firstDigit = formData.mobileNumber.trim().charAt(0);
            if (!['6', '7', '8', '9'].includes(firstDigit)) {
                formIsValid = false;
                errors.mobileNumber = 'Mobile number must start with 6, 7, 8, or 9';
            }
        }
        // if (!formData.featuresLookingFor || !formData.featuresLookingFor.trim()) {
        //     formIsValid = false;
        //     errors.featuresLookingFor = 'RestaurantName is required';
        // }

        if (!formData.city || !formData.city.trim()) {
            formIsValid = false;
            errors.city = 'City is required';
        }
        // Validate Restaurant Name
        if (!formData.restaurantName || !formData.restaurantName.trim()) {
            formIsValid = false;
            errors.restaurantName = 'Restaurant Name is required';
        }

        // // Validate Number of Stores
        // if (!formData.numberOfStores || formData.numberOfStores === '0') {
        //     formIsValid = false;
        //     errors.numberOfStores = 'Number of Stores is required';
        // }

        setFormErrors(errors);
        setHasError(!formIsValid);

        return formIsValid;
    };
    const resetForms1 = () => {
        setFormData({
            name: '',
            email: '',
            mobileNumber: '',
            city: '',
            restaurantName: '',
            numberOfStores: '',
            featuresLookingFor: ''
        });
    };
    const validateAndSubmitForm = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        if (validateForms()) {
            const combinedData = {
              name: formData.name,
              phone: formData.mobileNumber,
              email: formData.email,
              city: formData.city,
              businessName: formData.restaurantName,
              source: location.pathname,
              // numberOfStores: formData.numberOfStores,
              // featuresLookingFor: formData.featuresLookingFor
            };
            // const apiUrl = 'https://report-api.stage3.dotnu.co/api/merchant/external/enquiry';
              const apiUrl = process.env.REACT_APP_FORM_ONE;
            localStorage.setItem('mobile', formData.mobileNumber);
            try {
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json; charset=utf-8',
                    },
                    body: JSON.stringify(combinedData),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                console.log("Server Response:", data);
                // console.log("Inserted Data:", combinedData);
                resetForms1();
                // Navigate("/thankyou");
                navigate('/thankyou');
                // setShowThankYou(true);

            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    return (
        <Wrapper>

             <Helmet>
                <title>
                   Best Restaurant POS & Management System | Rista POS
                </title>
                <meta
                    name="description"
                    content="Best restaurant POS system to manage your restaurant billing, Inventory, Online Orders, KOT, Menu, Table Reservation & much more."
                />
                <meta
                    name="keywords"
                    content="Restaurant POS Software, Restaurant Billing Software, Restaurant Management Software"
                />
                <meta name="author" content="Rista Apps" />
                <meta
                    property="og:description"
                    content="Best restaurant POS system to manage your restaurant billing, Inventory, Online Orders, KOT, Menu, Table Reservation & much more."
                />
                <meta
                    property="og:image"
                    content={require("../../images/logo-w.png")}
                />
            </Helmet>

            <div>
                <section className="header">
                    <div className="container">
                        <div className="head-main">
                            <div className="head-inner">
                                <NavLink to="/"> <img src={require('../../imagesrista/logo.png')}></img> </NavLink>
                            </div>
                            <div className="head-inner">
                                <a className="head-btn" href="#contact-form"                                       onClick={(e) => {
                                        e.preventDefault();
                                        document.querySelector('#contact-form').scrollIntoView({
                                          behavior: 'smooth'
                                        });
                                      }}> Get a Demo </a>
                            </div>
                        </div>
                    </div>
                </section>
                

                <section className='banner'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-7 col-md-12 col-sm-12 col-xs-12'>
                                <div className='banner-content'>
                                    <h1 className='banner-heading'>Restaurant POS system for <span className="up-head"><span className='spans'>streamlined operations</span></span></h1>
                                    <p className='banner-para'>Stop managing & start thriving with Rista - <span >the ultimate restaurant management software</span></p>
                                    <div className="bannering-img bannering-img-none">
                                        <div className='banner-image'>
                                        </div>
                                        <img src={require('../../imagesrista/HeroPOS.webp')} alt="Restraurant POS delivery screen - Rista restraunt POS" title="Restaurant POS delivery screen - Rista restaurant POS"></img>
                                    </div>
                                    <div className='ban-first'> <span>See how Rista POS can transform your restaurant</span></div>
                                    {/* <div className='ban-bt'>
                                        <a href="#contact-form" style={{ textDecoration: "none" }}>Start your free trial</a>
                                    </div> */}
                                    <div className='banner-mobile'>
                                        <div className='ban-last'><img className='ban-last-img' src={require('../../imagesrista/star.png')}></img> Top 20 Technology Startups, <span> LinkedIn, 2023</span></div>
                                        <div className='ban-last'><img className='ban-last-img' src={require('../../imagesrista/star.png')}></img> India’s Best POS, <span> ET Hospitality World, 2022</span></div>
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-5 col-md-12 col-sm-12 col-xs-12'>
                                <div className="bannering-img bannering-img-block">
                                    <div className='banner-image'>

                                    </div>
                                    {/* <img src={require('../../imagesrista/HeroPOS.webp')} alt="Restraurant POS delivery screen - Rista restraunt POS" title="Restaurant POS delivery screen - Rista restaurant POS" /> */}
                                    <div className="add-contact add-contact-new">
                                        <div className="form-content">
                                            <h3>Start Your Free Trial Today</h3>
                                        </div>
                                        <div className="contact-form">
                                            {showThankYou1 && <h4 style={{ color: "green" }}>Thank you for contacting us!</h4>}

                                            <form action="/action_page.php">
                                                <div className="form-group">
                                                   
                                                    <label>Name</label>
                                                    <input type="text" className="form-control" placeholder="Enter Full Name*" id="name"
                                                        name="name1" required="required"
                                                        value={formData.name1}
                                                        onChange={handleChangess}
                                                    />
                                                    {formErrors.name1 && <span className="error">{formErrors.name1}</span>}
                                                </div>
                                                <div className="form-group">
                                                   
                                                    <label>Phone</label>
                                                    <input type="tel" className="form-control" placeholder="Enter Mobile Number*" id="number"
                                                        name="mobileNumber1"
                                                        required="required"
                                                        value={formData.mobileNumber1}
                                                        onChange={handleChangess}
                                                    />
                                                    {formErrors.mobileNumber1 && <span className="error">{formErrors.mobileNumber1}</span>}
                                                </div>

                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input type="email" className="form-control" placeholder="Enter Your Email Address*" id="email"
                                                        name="email1"
                                                        required="required"
                                                        value={formData.email1}
                                                        onChange={handleChangess}
                                                    />
                                                    {formErrors.email1 && <span className="error">{formErrors.email1}</span>}
                                                </div>
                                                <div className="form-group">
                                                    <label>City</label>
                                                    <input type="text" className="form-control" placeholder="City*" id="city"
                                                        required="required"
                                                        name="city1"
                                                        value={formData.city1}
                                                        onChange={handleChangess}
                                                    />
                                                    {formErrors.city1 && <span className="error">{formErrors.city1}</span>}
                                                </div>
                                                <div className="form-group">
                                                    {/* <label>Restaurant Name</label> */}
                                                    <label>Restaurant</label>
                                                    <input type="text" className="form-control" placeholder="Restaurant Name*" id="restaurant-name" required="required"
                                                        name="restaurantName1"
                                                        value={formData.restaurantName1}
                                                        onChange={handleChangess}
                                                    />
                                                    {formErrors.restaurantName1 && <span className="error">{formErrors.restaurantName1}</span>}
                                                </div>
                                                

                                                <button type="submit" className="btn btn-primary" onClick={handleSumbit}>Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="sec-3 new-sec-addd" id="contact-form">
                    <div className="container">
                        <div className="sec-3-inner">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-content-today">
                                        <div className="form-content">
                                            <h3>Limited spots available</h3>
                                            <h2>Start your free trial</h2>
                                        </div>
                                        <div className="contact-form">
                                            
                                            {showThankYou && <h4 style={{ color: "green" }}>Thank you for contacting us!</h4>}

                                            <form>
                                                <div className="form-group">
                                                    
                                                    <label>Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Full Name*"
                                                        id="name"
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    {formErrors.name && <span className="error">{formErrors.name}</span>}
                                                </div>
                                                <div className="form-group">
                                                    
                                                    <label>Phone</label>
                                                    <input
                                                        type="tel"
                                                        className="form-control"
                                                        placeholder="Enter Mobile Number*"
                                                        id="mobileNumber"
                                                        name="mobileNumber"
                                                        value={formData.mobileNumber}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    {formErrors.mobileNumber && <span className="error">{formErrors.mobileNumber}</span>}
                                                </div>

                                                <div className="form-group">
                                                   
                                                    <label>Email</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="Enter Your Email Address*"
                                                        id="email"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    {formErrors.email && <span className="error">{formErrors.email}</span>}
                                                </div>
                                                <div className="form-group">
                                                    <label>City</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="City*"
                                                        id="city"
                                                        name="city"
                                                        value={formData.city}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    {formErrors.city && <span className="error">{formErrors.city}</span>}
                                                </div>
                                                <div className="form-group">
                                                   
                                                    <label>Restaurant</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Restaurant Name*"
                                                        id="restaurantName"
                                                        name="restaurantName"
                                                        value={formData.restaurantName}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    {formErrors.restaurantName && <span className="error">{formErrors.restaurantName}</span>}
                                                </div>
                                                <button type="submit" className="btn btn-primary"
                                                    onClick={validateAndSubmitForm}
                                                >Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='sec-2'>
                    <div className='container-fluids'>
                        <p className="para"><b>Thousands</b> of restaurants <b>run and grow</b>  <img src={require('../../imagesrista/till-arrow.png')}></img> on <b>Rista</b>, every day.</p>

                        <div className='owl-slider desktop-slider'>
                            <OwlCarousel
                                items={20}
                                className='owl-theme'
                                loop={true}
                                nav={false}
                                dots={false}
                                margin={8}
                                autoplay={true}
                                autoplayTimeout={2000}
                                responsive={{
                                    0: { items: 5 }, // Set 1 item for small screens (width < 600px)
                                    600: { items: 8 }, // Set 2 items for medium screens (600px <= width < 960px)
                                    1025: { items: 12 },
                                    1299: { items: 18 }, // Set 4 items for larger screens (width >= 960px)
                                }}
                            >
                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p1.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p2.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p3.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p4.webp')}></img>
                                    </div>
                                </div>

                                {/* <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p5.webp')}></img>
                                    </div>
                                </div> */}

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p6.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p7.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p8.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p9.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p10.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p11.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p12.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p13.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p14.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p15.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p16.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p17.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p18.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p19.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p20.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p21.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p22.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p23.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p24.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p25.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p26.webp')}></img>
                                    </div>
                                </div>


                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p27.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p28.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p29.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p30.webp')}></img>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                        <div className='owl-slider mobile-slider-1'>
                            <OwlCarousel
                                items={20}
                                className='owl-theme'
                                loop
                                nav={false}
                                dots={false}
                                margin={8}
                                autoplay={true}
                                autoplayTimeout={2000}
                                responsive={{
                                    0: { items: 4, stagePadding: 40, margin: 10 },
                                    400: { items: 5, stagePadding: 40, margin: 10 },
                                    500: { items: 6, stagePadding: 40, margin: 10 },
                                }}
                            >
                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p1.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p2.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p3.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p4.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p5.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p6.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p7.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p8.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p9.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p10.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p11.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p12.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p13.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p14.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p15.webp')}></img>
                                    </div>
                                </div>


                            </OwlCarousel>
                        </div>
                        <div className='owl-slider mobile-slider-2'>
                            <OwlCarousel
                                items={20}
                                className='owl-theme'
                                loop={true}
                                autoplay={true}
                                autoplayTimeout={2000}
                                nav={false}
                                dots={false}
                                margin={8}
                                responsive={{
                                    0: { items: 4, stagePadding: 40, margin: 10 },
                                    400: { items: 5, stagePadding: 40, margin: 10 },
                                    500: { items: 6, stagePadding: 40, margin: 10 },
                                }}
                            >

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p16.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p17.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p18.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p19.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p20.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p21.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p22.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p23.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p24.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p25.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p26.webp')}></img>
                                    </div>
                                </div>


                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p27.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p28.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p29.webp')}></img>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='restaurants-img'>
                                        <img src={require('../../imagesrista/p30.webp')}></img>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </section>
                {/* <section className="sec-3" id="contact-form">
                    <div className="container">
                        <div className="sec-3-inner">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 row-none">
                                    <div className="secing-img-ban">
                                        <img src={require('../../imagesrista/Leadgen-form.webp')} alt="Man taking order through rista restraunt POS software" title="Man taking order through rista restraunt POS software" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-content-today">
                                        <div className="form-content">
                                            <h3>Limited spots available</h3>
                                            <h2>Start your free <span>Rista POS trial</span></h2>
                                            <h4>We’ll reach out within 24 hours to schedule a free demo.</h4>
                                        </div>
                                        <div className="contact-form">
                                            
                                            {showThankYou && <h4 style={{ color: "green" }}>Thank you for contacting us!</h4>}

                                            <form>
                                                <div className="form-group">
                                                    
                                                    <label>Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Full Name*"
                                                        id="name"
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    {formErrors.name && <span className="error">{formErrors.name}</span>}
                                                </div>
                                                <div className="form-group">
                                                    
                                                    <label>Phone</label>
                                                    <input
                                                        type="tel"
                                                        className="form-control"
                                                        placeholder="Enter Mobile Number*"
                                                        id="mobileNumber"
                                                        name="mobileNumber"
                                                        value={formData.mobileNumber}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    {formErrors.mobileNumber && <span className="error">{formErrors.mobileNumber}</span>}
                                                </div>

                                                <div className="form-group">
                                                   
                                                    <label>Email</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="Enter Your Email Address*"
                                                        id="email"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    {formErrors.email && <span className="error">{formErrors.email}</span>}
                                                </div>
                                                <div className="form-group">
                                                    <label>City</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="City*"
                                                        id="city"
                                                        name="city"
                                                        value={formData.city}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    {formErrors.city && <span className="error">{formErrors.city}</span>}
                                                </div>
                                                <div className="form-group">
                                                   
                                                    <label>Restaurant</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Restaurant Name*"
                                                        id="restaurantName"
                                                        name="restaurantName"
                                                        value={formData.restaurantName}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    {formErrors.restaurantName && <span className="error">{formErrors.restaurantName}</span>}
                                                </div>
                                                <button type="submit" className="btn btn-primary"
                                                    onClick={validateAndSubmitForm}
                                                >Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section className="sec-4">
                    <div className="container">

                        <h3 className="sub-heading text-black">What makes Rista the best restaurant POS software?</h3>
                        <h2 className="heading text-black">Most loved benefits of Rista POS <span> restaurant management system </span></h2>

                        <div className="row row-ceb-jsu">
                            <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                                <div className='full-img'>
                                    <img class="pc-sec-4-img" src={require('../../imagesrista/Most-Loved.webp')} alt="Rista restraunt pos works on all devices" title="Rista restraunt pos works on all devices"></img>
                                    <img class="mobile-sec-4-img" src={require('../../imagesrista/Most-Loved.webp')} alt="Rista restraunt pos works on all devices" title="Rista restraunt pos works on all devices"></img>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <div className="sec-4-inner">
                                    <h3>Customer analytics</h3>
                                    <p>Understand your customer base, their preferences, and buying habits to personalize your offerings and drive loyalty.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <div className="sec-4-inner">
                                    <h3>Waste reduction</h3>
                                    <p>Optimize your ordering based on actual usage data, minimizing food waste and saving money.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <div className="sec-4-inner">
                                    <h3>Upselling & promotions</h3>
                                    <p>Recommend popular items, offer targeted discounts, and boost your bottom line with ease.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <div className="sec-4-inner sec-4-inner-ch">
                                    <h3>Faster service</h3>
                                    <p>Take orders, send them to the kitchen, and process payments with lightning speed, reducing wait times and keeping customers happy.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <div className="sec-4-inner sec-4-inner-ch">
                                    <h3>Real-time tracking</h3>
                                    <p>Monitor inventory levels in real-time, preventing stock-outs and ensuring you always have what your customers crave.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <div className="sec-4-inner sec-4-inner-ch">
                                    <h3>Automated ordering</h3>
                                    <p>Set up automatic purchase orders to restock essential items before you run out.</p>
                                </div>
                            </div>

                        </div>
                        <div className="row row-align">
                            <div className="col-lg-4 col-md-6 col-sm-8 col-xs-12 mobile-space-0">
                                <div className='ban-bt'>
                                    <a href="#contact-form1"  
                                      onClick={(e) => {
                                        e.preventDefault();
                                        document.querySelector('#contact-form1').scrollIntoView({
                                          behavior: 'smooth'
                                        });
                                      }}
                                    style={{ textDecoration: "none" }}>Start your free trial</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='sec-7'>
                    <div className='container'>
                        <div className="row">
                            <h2 className="heading text-white">What makes Rista restaurant  <span> software even better? </span></h2>
                        </div>
                        <div className="row">
                            <div className='sec-7-block'>
                                <div className='sec-1-block'>
                                    <div className='sec-7-img'>
                                        <img src={require('../../imagesrista/icon-1.png')}></img>
                                        <h3>Role-based <span>acess</span></h3>
                                        <p>Robust permissions control engine with approvals</p>
                                    </div>
                                </div>
                                <div className='sec-1-block'>
                                    <div className='sec-7-img'>
                                        <img src={require('../../imagesrista/icon-2.png')}></img>
                                        <h3>Cloud-<span>based</span></h3>
                                        <p>Accessible, no local server required</p>
                                    </div>
                                </div>
                                <div className='sec-1-block'>
                                    <div className='sec-7-img'>
                                        <img src={require('../../imagesrista/icon-3.png')}></img>
                                        <h3>Analytics & <span> reporting</span></h3>
                                        <p>Seamless data analysis for optimized menu engineering</p>
                                    </div>
                                </div>
                                <div className='sec-1-block'>
                                    <div className='sec-7-img'>
                                        <img src={require('../../imagesrista/icon-4.png')}></img>
                                        <h3>Highly <span> integrated </span></h3>
                                        <p>APIs for major aggregator apps</p>
                                    </div>
                                </div>
                                <div className='sec-1-block sec-7-img-ch'>
                                    <div className='sec-7-img'>
                                        <img src={require('../../imagesrista/icon-5.png')}></img>
                                        <h3>Multi-<span>store</span></h3>
                                        <p>Centralized kitchen and warehouse management for visibility & control</p>
                                    </div>
                                </div>
                                <div className='sec-1-block sec-7-img-ch'>
                                    <div className='sec-7-img'>
                                        <img src={require('../../imagesrista/icon-3.png')}></img>
                                        <h3>Multi-<span>device</span></h3>
                                        <p>Desktop, Mobile, PC, & Tablet compatible</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className='ban-bt'>
                                    <a href="#contact-form1" 
                                                                           onClick={(e) => {
                                                                            e.preventDefault();
                                                                            document.querySelector('#contact-form1').scrollIntoView({
                                                                              behavior: 'smooth'
                                                                            });
                                                                          }}
                                     style={{ textDecoration: "none" }}>Start your free trial</a>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row sec-7-btnrow">
                            <div className="col-md-12">
                                <div className='ban-bt'>
                                    <a href="#contact-form" style={{ textDecoration: "none" }}>Start your free trial</a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="container-fluid excelent-cont">
                        <div className="row"><div className="col-md-12"><div className="sec5ins"><span className="hm_staricon"><img className='ban-last-img' src={require('../../imagesrista/star.png')}></img></span><small><b>Top 20 Technology Startups</b></small><small className="et_customr">LinkedIn, 2023</small><span className="hm_starline">|</span><span className="hm_staricon"><img className='ban-last-img' src={require('../../imagesrista/star.png')}></img></span><small><b>India’s Best POS</b></small> <small className="et_customr">ET Hospitality World, 2022</small></div></div></div>
                    </div>
                </section>


                <section className="sec-5">
                    <div className='container'>
                        <h2 className="sub-heading">Rista POS features</h2>
                        <h3 className="heading heading-addd">Restaurant POS Software That  <span>Offers Everything You Need</span></h3>
                        <h2 className="sub-heading">Lightning-fast restaurant software that can handle peak order volumes with ease</h2>

                        <div className="row mid">
                            <div className='col-lg-7 col-md-6 col-sm-12 col-xs-12'>
                                <div className="accordion" id="faq">
                                    <div className="card">
                                        <div className="card-header" id="faqhead1">
                                            <a href="#" className="btn btn-header-link" data-toggle="collapse" data-target="#faq1"
                                                aria-expanded="true" aria-controls="faq1"

                                                // onClick={() =>
                                                // handlecardClick(require("../../imagesrista/1_Billing.webp"))
                                                // }
                                                onClick={() =>
                                                    handlecardClick(
                                                        require("../../imagesrista/1_Billing.webp"),
                                                        "Rista restaurant pos dine in order taking screen",
                                                        "Rista restaurant pos dine in order taking screen"
                                                    )
                                                }
                                            >Punch Orders in less than 10 seconds with restaurant billing software</a>
                                        </div>

                                        <div id="faq1" className="collapse show" aria-labelledby="faqhead1" data-parent="#faq">
                                            <div className="card-body">
                                                <div className="cont">
                                                    Offline billing -No internet connection or manual sync required, accept partial payments, apply offers & coupons, Store customer data for marketing & repeat orders, and send digital bills via WhatsApp, Email, SMS, and more with Rista restaurant billing software.
                                                </div>
                                                <div className="feature-btn">
                                                    <a class="sft-btn" href="#contact-form1"                                      onClick={(e) => {
                                        e.preventDefault();
                                        document.querySelector('#contact-form1').scrollIntoView({
                                          behavior: 'smooth'
                                        });
                                      }} style={{ textDecoration: "none" }}>Start your free trial</a>

                                                </div>
                                                {selectedImage &&
                                                    <div className='sec-5-img-mobile'>
                                                        <img
                                                            // src={selectedImage}
                                                            src={selectedImage.src}
                                                            alt={selectedImage.alt}
                                                            title={selectedImage.title}
                                                        />
                                                    </div>}

                                                <div className='sec-5-img-1'>
                                                    <img src={require('../../imagesrista/1_Billing.webp')}
                                                        alt="Restraunt software pos online screen" title="Restraunt software pos online screen"
                                                    ></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="faqhead21">
                                            <a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq21"
                                                aria-expanded="true" aria-controls="faq21"
                                                // onClick={() =>
                                                //     handlecardClick(require("../../imagesrista/2_Orders.webp"))
                                                // }
                                                onClick={() =>
                                                    handlecardClick(
                                                        require("../../imagesrista/2_Orders.webp"),
                                                        "Rista restaurant pos online order screen",
                                                        "Rista restaurant pos online order screen"
                                                    )
                                                }
                                            >Manage all online orders from a single screen</a>
                                        </div>
                                        <div id="faq21" className="collapse" aria-labelledby="faqhead21" data-parent="#faq">
                                            <div className="card-body">
                                                <div className="cont">
                                                    Automatically mark items out of stock based on inventory, auto-accept online orders, mark orders ready & dispatch, accept payment, and track the cycle of your order efficiently & more with Rista restaurant software.
                                                </div>
                                                <div className="feature-btn">
                                                    <a class="sft-btn" href="#contact-form1"                                       onClick={(e) => {
                                        e.preventDefault();
                                        document.querySelector('#contact-form1').scrollIntoView({
                                          behavior: 'smooth'
                                        });
                                      }}style={{ textDecoration: "none" }}>Start your free trial</a>
                                                    {/* <a class="emf-btn" href="#" style={{ textDecoration: "none" }}>Explore More Features</a> */}
                                                </div>
                                                {/* <img src={require('../../imagesrista/sec-5-img.jpg')}></img> */}
                                                {selectedImage &&
                                                    <div className='sec-5-img-mobile'>
                                                        <img
                                                            // src={selectedImage} 
                                                            src={selectedImage.src}
                                                            alt={selectedImage.alt}
                                                            title={selectedImage.title}
                                                        />
                                                    </div>}
                                                {selectedImage &&
                                                    <div className='sec-5-img-1'>
                                                        <img
                                                            // src={selectedImage} 
                                                            src={selectedImage.src}
                                                            alt={selectedImage.alt}
                                                            title={selectedImage.title}
                                                        />
                                                    </div>}
                                                <div className='sec-5-img-1'>
                                                    <img src={require('../../imagesrista/2_Orders.webp')}></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="faqhead3">
                                            <a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq3"
                                                aria-expanded="true" aria-controls="faq3"
                                                // onClick={() =>
                                                //     handlecardClick(require("../../imagesrista/3_Inventory.webp"))
                                                // }
                                                onClick={() =>
                                                    handlecardClick(
                                                        require("../../imagesrista/3_Inventory.webp"),
                                                        "Restraunt pos inventory management screen - Rista restraunt software",
                                                        "Restraunt pos inventory management screen - Rista restraunt software"
                                                    )
                                                }
                                            >Best restaurant inventory management software</a>
                                        </div>

                                        <div id="faq3" className="collapse" aria-labelledby="faqhead3" data-parent="#faq">
                                            <div className="card-body">
                                                <div className="cont">
                                                    Manage stock-in and auto stock-out, Get low stock alerts, day-end inventory reports, Recipe Management, Vendor Management, Franchise Management, and more with the best Inventory features offered with Rista Inventory Management Software.
                                                </div>
                                                <div className="feature-btn">
                                                    <a class="sft-btn" href="#contact-form1"                                       onClick={(e) => {
                                        e.preventDefault();
                                        document.querySelector('#contact-form1').scrollIntoView({
                                          behavior: 'smooth'
                                        });
                                      }}style={{ textDecoration: "none" }}>Start your free trial</a>
                                                    {/* <a class="emf-btn" href="#" style={{ textDecoration: "none" }}>Explore More Features</a> */}
                                                </div>
                                                {selectedImage &&
                                                    <div className='sec-5-img-mobile'>
                                                        <img
                                                            // src={selectedImage}
                                                            src={selectedImage.src}
                                                            alt={selectedImage.alt}
                                                            title={selectedImage.title}
                                                        />
                                                    </div>}
                                                <div className='sec-5-img-1'>
                                                    <img src={require('../../imagesrista/3_Inventory.webp')}></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="faqhead4">
                                            <a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq4"
                                                aria-expanded="true" aria-controls="faq4"
                                                // onClick={() =>
                                                //     handlecardClick(require("../../imagesrista/Flexible-restaurant.webp"))
                                                // }
                                                onClick={() =>
                                                    handlecardClick(
                                                        require("../../imagesrista/Flexible-restaurant.webp"),
                                                        "",
                                                        ""
                                                    )
                                                }
                                            >Flexible restaurant menu management</a>
                                        </div>

                                        <div id="faq4" className="collapse" aria-labelledby="faqhead4" data-parent="#faq">
                                            <div className="card-body">
                                                <div className="cont">
                                                    Display Items as per the desired schedule in the menu, Multi-channel & multi-location menu with customizable prices without duplicate SKUs, Automatic material-based stock-out options, Centrally managed status for all outlets, and more with Rista Restaurant Menu Management Software.
                                                </div>
                                                <div className="feature-btn">
                                                    <a class="sft-btn" href="#contact-form1"                                       onClick={(e) => {
                                        e.preventDefault();
                                        document.querySelector('#contact-form1').scrollIntoView({
                                          behavior: 'smooth'
                                        });
                                      }}style={{ textDecoration: "none" }}>Start your free trial</a>
                                                    {/* <a class="emf-btn" href="#" style={{ textDecoration: "none" }}>Explore More Features</a> */}
                                                </div>
                                                {selectedImage &&
                                                    <div className='sec-5-img-mobile'>
                                                        <img
                                                            // src={selectedImage}
                                                            src={selectedImage.src}
                                                            alt={selectedImage.alt}
                                                            title={selectedImage.title}
                                                        />
                                                    </div>}
                                                <div className='sec-5-img-1'>
                                                    <img src={require('../../imagesrista/sec-5-img.jpg')}></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="faqhead5">
                                            <a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq5"
                                                aria-expanded="true" aria-controls="faq5"
                                                // onClick={() =>
                                                //     handlecardClick(require("../../imagesrista/4_analytics.webp"))
                                                // }
                                                onClick={() =>
                                                    handlecardClick(
                                                        require("../../imagesrista/4_analytics.webp"),
                                                        "Reports screen in rista restraunt pos software",
                                                        "Reports screen in rista restraunt pos software"
                                                    )
                                                }
                                            >Restaurant reports with actionable insights</a>
                                        </div>

                                        <div id="faq5" className="collapse" aria-labelledby="faqhead5" data-parent="#faq">
                                            <div className="card-body">
                                                <div className="cont">
                                                    Single dashboard to analyze trends in data, Target vs actual sales for individual outlets, Payout reports – by Swiggy/Zomato/3PA, Automatic report triggers with warnings for low sales, Variance analysis of business metrics like sales & food costing,  Better Visibility, Data-backed decisions, Tighter Controls etc. with Rista POS Reports & Analytics module.
                                                </div>
                                                <div className="feature-btn">
                                                    <a class="sft-btn" href="#contact-form1"                                       onClick={(e) => {
                                        e.preventDefault();
                                        document.querySelector('#contact-form1').scrollIntoView({
                                          behavior: 'smooth'
                                        });
                                      }}style={{ textDecoration: "none" }}>Start your free trial</a>
                                                    {/* <a class="emf-btn" href="#" style={{ textDecoration: "none" }}>Explore More Features</a> */}
                                                </div>
                                                {selectedImage &&
                                                    <div className='sec-5-img-mobile'>
                                                        <img
                                                            // src={selectedImage}
                                                            src={selectedImage.src}
                                                            alt={selectedImage.alt}
                                                            title={selectedImage.title}
                                                        />
                                                    </div>}
                                                <div className='sec-5-img-1'>
                                                    <img src={require('../../imagesrista/4_analytics.webp')}></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-5 col-md-6 col-sm-12 col-xs-12 hide-1'>
                                {selectedImage &&
                                    <div className='sec-5-img'>
                                        <img
                                            // src={selectedImage}
                                            src={selectedImage.src}
                                            alt={selectedImage.alt}
                                            title={selectedImage.title}
                                        ></img>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>


                <section className="sec-3 sec-8" id="contact-form1" title="successfull restaurants run on insta restaurant pos">
                    <div className="container">
                        <div className="sec-3-inner">
                            <div className="row">
                                <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                                    <div className="sec-8-inner-block">
                                        <h2 className="sub-heading">Skyrocket your restaurant capabilities</h2>
                                        <h3 className="heading"> Let us handle your restaurant operations while you focus on delighting your guests</h3>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                                    <div className="add-contact">
                                        <div className="form-content">
                                            <h3>Start Your Free Trial Today</h3>
                                        </div>
                                        <div className="contact-form">
                                            {showThankYou1 && <h4 style={{ color: "green" }}>Thank you for contacting us!</h4>}

                                            <form action="/action_page.php">
                                                <div className="form-group">
                                                    {/* <label>Full Name</label> */}
                                                    <label>Name</label>
                                                    <input type="text" className="form-control" placeholder="Enter Full Name*" id="name"
                                                        name="name1" required="required"
                                                        value={formData.name1}
                                                        onChange={handleChangess}
                                                    />
                                                    {formErrors.name1 && <span className="error">{formErrors.name1}</span>}
                                                </div>
                                                <div className="form-group">
                                                    {/* <label>Mobile Number</label> */}
                                                    <label>Phone</label>
                                                    <input type="tel" className="form-control" placeholder="Enter Mobile Number*" id="number"
                                                        name="mobileNumber1"
                                                        required="required"
                                                        value={formData.mobileNumber1}
                                                        onChange={handleChangess}
                                                    />
                                                    {formErrors.mobileNumber1 && <span className="error">{formErrors.mobileNumber1}</span>}
                                                </div>

                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input type="email" className="form-control" placeholder="Enter Your Email Address*" id="email"
                                                        name="email1"
                                                        required="required"
                                                        value={formData.email1}
                                                        onChange={handleChangess}
                                                    />
                                                    {formErrors.email1 && <span className="error">{formErrors.email1}</span>}
                                                </div>
                                                <div className="form-group">
                                                    <label>City</label>
                                                    <input type="text" className="form-control" placeholder="City*" id="city"
                                                        required="required"
                                                        name="city1"
                                                        value={formData.city1}
                                                        onChange={handleChangess}
                                                    />
                                                    {formErrors.city1 && <span className="error">{formErrors.city1}</span>}
                                                </div>
                                                <div className="form-group">
                                                    {/* <label>Restaurant Name</label> */}
                                                    <label>Restaurant</label>
                                                    <input type="text" className="form-control" placeholder="Restaurant Name*" id="restaurant-name" required="required"
                                                        name="restaurantName1"
                                                        value={formData.restaurantName1}
                                                        onChange={handleChangess}
                                                    />
                                                    {formErrors.restaurantName1 && <span className="error">{formErrors.restaurantName1}</span>}
                                                </div>
                                                {/* <div className="form-group">
                                                <label>No Of Stores</label>
                                                <select className="form-control"
                                                    name="numberOfStores"
                                                    value={formData.numberOfStores}
                                                    onChange={handleChanges}>
                                                    <option val="0">No Of Stores...</option>
                                                    <option val="1">1</option>
                                                    <option val="2">2</option>
                                                    <option val="3">3</option>
                                                    <option val="4">4</option>
                                                    <option val="5">5</option>
                                                    <option val="6">6</option>
                                                </select>
                                                {formErrors.numberOfStores && <span className="error">{formErrors.numberOfStores}</span>}
                                            </div>
                                            <div className="form-group">
                                                <label>Features Looking For</label>
                                                <input type="text" className="form-control" placeholder="Features Looking For" id="features-look"
                                                    name="featuresLookingFor"
                                                    value={formData.featuresLookingFor}
                                                    onChange={handleChanges}
                                                />
                                                {formErrors.featuresLookingFor && <span className="error">{formErrors.featuresLookingFor}</span>}
                                            </div> */}

                                                <button type="submit" className="btn btn-primary" onClick={handleSumbit}>Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="sec-9">
                    <div className="container mobile-container-pading-zero">
                        <div className="row">
                            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                                <h2 className="sub-heading">Rista POS</h2>
                                <h3 className="heading">One software for all types of restaurants</h3>
                                <h2 className="sub-heading sec9-again">Rista restaurant POS software’s modular design serves all types of restaurants</h2>
                            </div>
                            <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 padding-zero">
                                <div className="pc-contact">
                                    <div className="pc-contact-block">
                                        <span className="pc-contact-icon">
                                            <img src={require('../../imagesrista/pc-mobile-icon-1.png')}></img>
                                        </span>
                                        <span className="pc-contact-cont">
                                            <h4>Cloud Kitchen </h4>
                                            {/* <span>(Emerging in INDIA)</span> */}
                                        </span>
                                    </div>
                                    <div className="pc-contact-block">
                                        <span className="pc-contact-icon">
                                            <img src={require('../../imagesrista/pc-mobile-icon-2.png')}></img>
                                        </span>
                                        <span className="pc-contact-cont">
                                            <h4>Bakery </h4>
                                            <span></span>
                                        </span>
                                    </div>
                                    <div className="pc-contact-block">
                                        <span className="pc-contact-icon">
                                            <img src={require('../../imagesrista/pc-mobile-icon-3.png')}></img>
                                        </span>
                                        <span className="pc-contact-cont">
                                            <h4>Bar & Brewery </h4>
                                            <span></span>
                                        </span>
                                    </div>
                                    <div className="pc-contact-block">
                                        <span className="pc-contact-icon">
                                            <img src={require('../../imagesrista/pc-mobile-icon-4.png')}></img>
                                        </span>
                                        <span className="pc-contact-cont">
                                            <h4>QSR </h4>
                                            <span></span>
                                        </span>
                                    </div>
                                    <div className="pc-contact-block">
                                        <span className="pc-contact-icon">
                                            <img src={require('../../imagesrista/pc-mobile-icon-5.png')}></img>
                                        </span>
                                        <span className="pc-contact-cont">
                                            <h4>Canteen or Food Court </h4>
                                            <span></span>
                                        </span>
                                    </div>
                                    <div className="pc-contact-block">
                                        <span className="pc-contact-icon">
                                            <img src={require('../../imagesrista/pc-mobile-icon-6.png')}></img>
                                        </span>
                                        <span className="pc-contact-cont">
                                            <h4>Cafe & Coffee Shop </h4>
                                            <span></span>
                                        </span>
                                    </div>
                                    <div className="pc-contact-block">
                                        <span className="pc-contact-icon">
                                            <img src={require('../../imagesrista/pc-mobile-icon-7.png')}></img>
                                        </span>
                                        <span className="pc-contact-cont">
                                            <h4>Fine Dine </h4>
                                            <span></span>
                                        </span>
                                    </div>
                                    <div className="pc-contact-block">
                                        <span className="pc-contact-icon">
                                            <img src={require('../../imagesrista/pc-mobile-icon-8.png')}></img>
                                        </span>
                                        <span className="pc-contact-cont">
                                            <h4>Food Truck or Cart </h4>
                                            <span></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </section>

                <section className="sec-5 sec-10">
                    <div className='container'>
                        <h2 className="sub-heading">Testimonials</h2>
                        <h3 className="heading heading-addd">Why Customers Love Rista <span>as the Best POS System</span></h3>
                        <h2 className="sub-heading"> Lightning-fast restaurant software that can<br /> handle peak order volumes with ease</h2>

                        <div className="row mid">
                            <div className='col-lg-8 col-md-12 col-sm-12 col-xs-12 padding-zero'>
                                <div className="accordion monial-accordian" id="faq2">
                                    <div className="card">
                                        <div className="card-header" id="faqhead51">
                                            <a href="#" className="btn btn-header-link" data-toggle="collapse" data-target="#faq51"
                                                aria-expanded="true" aria-controls="faq51"><img className="in-faq-img" src={require('../../imagesrista/1.webp')}></img> <span class="monial-head">Zing Restaurants  <small>Abhimanyu Maheshwari, Owner</small></span></a>
                                        </div>

                                        <div id="faq51" className="collapse show" aria-labelledby="faqhead51" data-parent="#faq2">
                                            <div className="card-body">
                                                <div className="cont">
                                                    DotPe's tech stack has truly transformed our operations. We seamlessly use it for both dine-in and cloud kitchens, & the automation of order acceptance has made our processes highly accurate & efficient. Their intuitive & customizable inventory management system has been a game-changer, helping us analyze our actual direct costs with precision.
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="faqhead6">
                                            <a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq6"
                                                aria-expanded="true" aria-controls="faq6" ><img className="in-faq-img" src={require('../../imagesrista/2.webp')}></img> <span class="monial-head">Chai Break <small> Aditya Ladasaria, Co-Founder</small></span></a>
                                        </div>
                                        <div id="faq6" className="collapse" aria-labelledby="faqhead6" data-parent="#faq2">
                                            <div className="card-body">
                                                <div className="cont">
                                                    Rista POS has streamlined our operations, making order management a breeze, & DotPe's integration with WhatsApp Marketing has boosted our customer engagement & sales. It's a winning combination that I highly recommend for any food business.
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="faqhead7">
                                            <a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq7"
                                                aria-expanded="true" aria-controls="faq7"><img className="in-faq-img" src={require('../../imagesrista/3.webp')}></img> <span class="monial-head">Eatfit <small> Ankit Nagori, Founder</small></span></a>
                                        </div>

                                        <div id="faq7" className="collapse" aria-labelledby="faqhead7" data-parent="#faq2">
                                            <div className="card-body">
                                                <div className="cont">
                                                    <p>EatFit has partnered with DotPe for various solutions for our cloud kitchen business & we are extremely happy with what we have seen. The team is extremely helpful & helped our team scale up on various areas on the tool especially the "whatsapp for business" which has helped us increase our D2C revenue significantly.</p>
                                                    <p>I am very thankful to the DotPe team for their support & looking forward to the journey forward.
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="faqhead8">
                                            <a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq8"
                                                aria-expanded="true" aria-controls="faq8"><img className="in-faq-img" src={require('../../imagesrista/4.webp')}></img> <span class="monial-head"> Indigo<small>  Anurag Katriar, Founder</small></span></a>
                                        </div>

                                        <div id="faq8" className="collapse" aria-labelledby="faqhead8" data-parent="#faq2">
                                            <div className="card-body">
                                                <div className="cont">
                                                    At Indigo, we've been using Rista for the past year & a half, & our experience has been nothing short of fabulous. Both their POS & MMS work beautifully, & the reports are crisp. Their service support is brilliant, & I have no hesitation in saying that Rista is a great product for the F&B business.
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="faqhead9">
                                            <a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq9"
                                                aria-expanded="true" aria-controls="faq9"><img className="in-faq-img" src={require('../../imagesrista/5.webp')}></img> <span class="monial-head"> Biryani Blues<small> Aparna Andrews , Co-Founder</small></span></a>
                                        </div>

                                        <div id="faq9" className="collapse" aria-labelledby="faqhead9" data-parent="#faq2">
                                            <div className="card-body">
                                                <div className="cont">
                                                    By far the best inventory management system in the market which made our operations smooth & hassle free.
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faqhead10">
                                            <a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq10"
                                                aria-expanded="true" aria-controls="faq10"><img className="in-faq-img" src={require('../../imagesrista/6.webp')}></img> <span class="monial-head">Big Chill <small> Aseem Grover, Owner</small></span></a>
                                        </div>

                                        <div id="faq10" className="collapse" aria-labelledby="faqhead10" data-parent="#faq2">
                                            <div className="card-body">
                                                <div className="cont">
                                                    Over the last three years, DotPe has been a game-changer for us. Its brilliant Ul, robust technology platform, & user-friendly kitchen dashboard have made our operations smoother than ever. Plus, the control it gives us over customer data is a game-changer for our business!
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faqhead11">
                                            <a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq11"
                                                aria-expanded="true" aria-controls="faq11"><img className="in-faq-img" src={require('../../imagesrista/7.webp')}></img> <span class="monial-head">Truffles <small> Avinash Bajaj,Managing Director</small></span></a>
                                        </div>

                                        <div id="faq11" className="collapse" aria-labelledby="faqhead11" data-parent="#faq2">
                                            <div className="card-body">
                                                <div className="cont">
                                                    We have been using Rista for the last 5 years, our all-in-one tech partner. Rista's advanced inventory & aggregator integrations have helped us improve our operational efficiency. The real-time stock updates have helped us reduce order cancellations, table QR ordering has increased our average per cover significantly.
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faqhead12">
                                            <a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq12"
                                                aria-expanded="true" aria-controls="faq12"><img className="in-faq-img" src={require('../../imagesrista/8.webp')}></img> <span class="monial-head">Impresario <small> Hardik,CTO</small></span></a>
                                        </div>

                                        <div id="faq12" className="collapse" aria-labelledby="faqhead12" data-parent="#faq2">
                                            <div className="card-body">
                                                <div className="cont">
                                                    DotPe has been the perfect partner for Impresario across our diverse restaurant portfolio. Their adaptability & dedication have made a significant difference in streamlining operations & improving customer experiences.
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faqhead13">
                                            <a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq13"
                                                aria-expanded="true" aria-controls="faq13"><img className="in-faq-img" src={require('../../imagesrista/9.webp')}></img> <span class="monial-head"> SodaBottleOpenerWala<small>Chef Irfan Pabaney , Country Head</small></span></a>
                                        </div>

                                        <div id="faq13" className="collapse" aria-labelledby="faqhead13" data-parent="#faq2">
                                            <div className="card-body">
                                                <div className="cont">
                                                    DotPe has helped us a great deal in making the whole order taking process for dine-in as well as delivery more seamless. The interface with imagesrista & descriptions helps in communicating our product better to the guests.
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faqhead14">
                                            <a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq14"
                                                aria-expanded="true" aria-controls="faq14"><img className="in-faq-img" src={require('../../imagesrista/15.webp')}></img> <span class="monial-head">Frozen Bottle <small> Pranshul Yadav, Founder</small></span></a>
                                        </div>

                                        <div id="faq14" className="collapse" aria-labelledby="faqhead14" data-parent="#faq2">
                                            <div className="card-body">
                                                <div className="cont">
                                                    DotPe's partnership has been a bridge between our brand & our audience.Their user-friendly & effective system has revolutionised our engagement strategies, helping us connect with our customers like never before.
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faqhead15">
                                            <a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq15"
                                                aria-expanded="true" aria-controls="faq15"><img className="in-faq-img" src={require('../../imagesrista/10.webp')}></img> <span class="monial-head">The Sassy Spoon <small> Rachel Goenka, Founder </small></span></a>
                                        </div>

                                        <div id="faq15" className="collapse" aria-labelledby="faqhead15" data-parent="#faq2">
                                            <div className="card-body">
                                                <div className="cont">
                                                    Rista Restaurant software has been a game-changer for us. With real-time performance monitoring, CRM integration, Loyalty programs, stock management, & enhanced security, it's transformed our operations & decision-making.
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faqhead16">
                                            <a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq16"
                                                aria-expanded="true" aria-controls="faq16"><img className="in-faq-img" src={require('../../imagesrista/11.webp')}></img> <span class="monial-head"> Barista <small>Rajat, CEO</small></span></a>
                                        </div>

                                        <div id="faq16" className="collapse" aria-labelledby="faqhead16" data-parent="#faq2">
                                            <div className="card-body">
                                                <div className="cont">
                                                    We have been working with the DotPe team over the last 3 years, our relationship started with Digital payment to now using Rista POS at Barista. Team has been quite proactive with their responses & engagement & is open to feedback & their quick implementation.
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faqhead17">
                                            <a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq17"
                                                aria-expanded="true" aria-controls="faq17"><img className="in-faq-img" src={require('../../imagesrista/12.webp')}></img> <span class="monial-head"> Burgrill<small>Shreh Madan , Founder</small></span></a>
                                        </div>

                                        <div id="faq17" className="collapse" aria-labelledby="faqhead17" data-parent="#faq2">
                                            <div className="card-body">
                                                <div className="cont">
                                                    Rista POS has truly transformed our experience at Burgrill, especially when it comes to billing and aggregator integration. It's not just a tool; it's become an invaluable part of our daily operations.
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="faqhead18">
                                            <a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq18"
                                                aria-expanded="true" aria-controls="faq18"><img className="in-faq-img" src={require('../../imagesrista/13.webp')}></img> <span class="monial-head">Mad Over Donuts<small> Tarak Bhattacharya , Executive Director & CEO </small></span></a>
                                        </div>

                                        <div id="faq18" className="collapse" aria-labelledby="faqhead18" data-parent="#faq2">
                                            <div className="card-body">
                                                <div className="cont">
                                                    I've always believed in digitization for efficiency, & Rista POS has been the perfect partner in helping us scale our business. They've been a true growth enabler, making our journey smoother & more successful.
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                {/* <section className="sec-3 sec-8 sec-11">
                <div className="container">
                    <div className="sec-3-inner">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <h2 className="sub-heading text-white">Limited Spots Available:</h2>
                                <h3 className="heading text-white">Start Your Free Rista Trial</h3>
                            </div>
                            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                                <div className="add-contact">
                                    <div className="form-content">
                                        <h3>Enter your Details</h3>
                                    </div>
                                    <div className="contact-form">
                                        <form action="/action_page.php">
                                        <div className="form-group">
                                        <label>Full Name</label>
                                                <input type="text" className="form-control" placeholder="Enter Full Name*" id="name" required="required" />
                                            </div>
                                            <div className="form-group">
                                            <label>Mobile Number</label>
                                                <input type="tel" className="form-control" placeholder="Enter Mobile Number*" id="number" required="required" />
                                            </div>
                                            
                                            <div className="form-group">
                                            <label>Email</label>
                                                <input type="email" className="form-control" placeholder="Enter Your Email Address*" id="email" required="required" />
                                            </div>
                                            <div className="form-group">
                                            <label>City</label>
                                                <input type="text" className="form-control" placeholder="City*" id="city" required="required" />
                                            </div>
                                            <div className="form-group">
                                            <label>Restaurant Name</label>
                                            <input type="text" className="form-control" placeholder="Restaurant Name*" id="restaurant-name" required="required" />
                                        </div>
                                        <div className="form-group">
                                        <label>No Of Stores</label>
                                            <select className="form-control">
                                                <option val="0">No Of Stores...</option>
                                                <option val="1">1</option>
                                                <option val="2">2</option>
                                                <option val="3">3</option>
                                                <option val="4">4</option>
                                                <option val="5">5</option>
                                                <option val="6">6</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                        <label>Features Looking For</label>
                                            <input type="text" className="form-control" placeholder="Features Looking For" id="features-look" />
                                        </div>

                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
                <section className="sec-3 sec-8 sec-8-new" id="contact-form" title="successfull restaurants run on insta restaurant pos">
                    <div className="container">
                        <div className="sec-3-inner">
                            <div className="row">
                                <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                                    <div className="sec-8-inner-block">
                                        <h2 className="sub-heading">Skyrocket your restaurant capabilities</h2>
                                        <h3 className="heading"> Let us handle your restaurant operations while you focus on delighting your guests</h3>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                                    <div className="add-contact">
                                        <div className="form-content">
                                            <h3>Start Your Free Trial Today</h3>
                                        </div>
                                        <div className="contact-form">
                                            {/* <h1>Thank You For Contacting us!!!!!!!!</h1> */}
                                            {showThankYou2 && <h4 style={{ color: "green" }}>Thank you for contacting us!</h4>}
                                            <form action="/action_page.php">
                                                <div className="form-group">
                                                    {/* <label>Full Name</label> */}
                                                    <label>Name</label>
                                                    <input type="text" className="form-control" placeholder="Enter Full Name*" id="name" required="required"
                                                        // id="name"
                                                        name="name2"
                                                        value={formData.name2}
                                                        onChange={handleChanges}
                                                    />
                                                    {formErrors.name2 && <span className="error">{formErrors.name2}</span>}
                                                </div>
                                                <div className="form-group">
                                                    {/* <label>Mobile Number</label> */}
                                                    <label>Phone</label>
                                                    <input type="tel" className="form-control" placeholder="Enter Mobile Number*" id="number"
                                                        name="mobileNumber2"
                                                        value={formData.mobileNumber2}
                                                        onChange={handleChanges}
                                                        required="required" />
                                                    {formErrors.mobileNumber2 && <span className="error">{formErrors.mobileNumber2}</span>}
                                                </div>

                                                <div className="form-group">
                                                    {/* <label>Email</label> */}
                                                    <label>Email</label>
                                                    <input type="email" className="form-control" placeholder="Enter Your Email Address*" id="email" required="required"
                                                        name="email2"
                                                        value={formData.email2}
                                                        onChange={handleChanges}
                                                    />
                                                    {formErrors.email2 && <span className="error">{formErrors.email2}</span>}
                                                </div>
                                                <div className="form-group">
                                                    <label>City</label>
                                                    <input type="text" className="form-control" placeholder="City*" id="city" required="required"
                                                        name="city2"
                                                        value={formData.city2}
                                                        onChange={handleChanges} />
                                                    {formErrors.city2 && <span className="error">{formErrors.city2}</span>}
                                                </div>
                                                <div className="form-group">
                                                    {/* <label>Restaurant Name</label> */}
                                                    <label>Restaurant</label>
                                                    <input type="text" className="form-control" placeholder="Restaurant Name*" id="restaurant-name2"
                                                        required="required"
                                                        name="restaurantName2"
                                                        value={formData.restaurantName2}
                                                        onChange={handleChanges}
                                                    />
                                                    {formErrors.restaurantName2 && <span className="error">{formErrors.restaurantName2}</span>}
                                                </div>
                                                {/* <div className="form-group">
                                                <label>No Of Stores</label>
                                                <select className="form-control">
                                                    <option val="0">No Of Stores...</option>
                                                    <option val="1">1</option>
                                                    <option val="2">2</option>
                                                    <option val="3">3</option>
                                                    <option val="4">4</option>
                                                    <option val="5">5</option>
                                                    <option val="6">6</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>Features Looking For</label>
                                                <input type="text" className="form-control" placeholder="Features Looking For" id="features-look" />
                                            </div> */}

                                                <button type="submit" className="btn btn-primary" onClick={handleSumbit2}>Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="footer">
                    <div className="foot foot-1">
                        <a href="/"> <img src={require('../../imagesrista/logo.png')}></img> </a>
                    </div>
                    <div className="foot foot-middle">
                        <a href="https://ristaapps.com/termsconditions">Terms & Conditions</a>
                        <a href="https://ristaapps.com/privacypolicy">Privacy</a>
                    </div>
                    <div className="foot foot-2">
                        <a href="https://x.com/DotPe_India"><img src={Tweeter}></img></a>
                        <a href="https://www.linkedin.com/company/dotpein/"><img src={require('../../imagesrista/s2.png')}></img></a>
                        <a href="https://www.instagram.com/dotpe_india/"><img src={require('../../imagesrista/s3.png')}></img></a>
                    </div>
                </section>
                <section className='stick-foot'>
                    <a href="#contact-form1"                                       onClick={(e) => {
                                        e.preventDefault();
                                        document.querySelector('#contact-form1').scrollIntoView({
                                          behavior: 'smooth'
                                        });
                                      }}>Start your free trial </a>
                </section>
                {/* <Footer /> */}

            </div>
        </Wrapper>
    )
}

export default Restaurantpossoftware;
